<script>
import {
  Airtime,
  CableTV,
  DataPurchase,
  OnlineStores,
  Power
} from "@/components/dashboard/requests";

export default {
  name: "purchase",
  data() {
    return {
      navTabs: [
        {
          id: 1,
          name: "airtime",
          component: Airtime,
        },
        {
          id: 2,
          name: "data",
          component: DataPurchase,
        },
        {
          id: 9,
          name: "power",
          component: Power,
        },
        // {
        //   id: 3,
        //   name: "government payments",
        //   component: GovernmentPayments,
        // },
        {
          id: 4,
          name: "cable TV",
          component: CableTV,
        },
        {
          id: 5,
          name: "Online Stores",
          component: OnlineStores,
        },
        // {
        //   id: 6,
        //   name: "invoice payments",
        //   component: InvoicePayments,
        // },
        // {
        //   id: 7,
        //   name: "Travel and Hotel",
        //   component: TravelAndHotel,
        // },
        // {
        //   id: 8,
        //   name: "Event Tickets",
        //   component: EventTickets,
        // },
      ],
    };
  },
};
</script>

<template>
  <div class="row gap-lg-0 border-1 border mx-0 border-theme rounded-2">
    <div class="col-12 col-lg-2 p-0 border-end border-sm-end-0 border-theme">
      <div class="nav flex-column nav-pills gap-1 mb-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        <button
          v-for="(tab, index) in navTabs"
          :key="tab.id"
          class="nav-link nav-tabs-indicator text-capitalize rounded-0 py-3"
          :class="{ active: index == 0 }"
          :id="`v-pills-${tab.name.replace(/ /g, '-').toLocaleLowerCase()}-tab`"
          data-toggle="pill"
          :data-target="`#v-pills-${tab.name.replace(/ /g, '-').toLocaleLowerCase()}`"
          type="button"
          role="tab"
          aria-controls="v-pills-home"
          aria-selected="true">
          <span class="text-start d-block fs-7 text-capitalize text-theme">{{ tab.name }}</span>
        </button>
      </div>
    </div>

    <div class="col-12 col-lg-10 p-3">
      <div class="tab-content" id="v-pills-tabContent">
        <div
          v-for="(tab, index) in navTabs"
          :key="index"
          class="tab-pane fade show"
          :class="{ active: index == 0 }"
          :id="`v-pills-${tab.name.replace(/ /g, '-').toLocaleLowerCase()}`"
          role="tabpanel"
          aria-labelledby="v-pills-home-tab">
          <component v-if="tab.component" :is="tab.component" />
        </div>
      </div>
    </div>
  </div>
</template>
