const SupplierRoutes = [
  {
    path: "/supplier/register",
    name: "supplierRegister",
    component: () => import("@/views/public/supplier/register.vue"),
  },
  {
    path: "/supplier/dashboard",
    name: "supplierDashboard",
    component: () => import("@/views/dashboard/dashboard.vue"),
  },

  {
    path: "/supplier/items",
    name: "SupplierItems",
    component: () => import("@/views/supplier/item/index.vue"),
  },
  {
    path: "/supplier/payments",
    name: "SupplierPayments",
    component: () => import("@/views/supplier/payment/index.vue"),
  },
];
export default SupplierRoutes;
