<template>
  <div class="manage" v-if="loaded">
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="card-title">Archived Users</h4>
    </div>
    <div class="manage-body mt-2">
      <div class="card b-card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <!-- <th>Name</th> -->

                  <th>Email</th>
                  <th class="text-center">Archived By</th>
                  <th class="text-center">Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="users.length > 0">
                <tr v-for="(user, index) in users" :key="index">
                  <!-- <td>{{ user.name }}</td> -->
                  <td>{{ user.email }}</td>
                  <td class="text-center">{{ user.deletedBy }}</td>
                  <td class="text-center">
                    {{ formatDateTime(user.deletedAt) }}
                  </td>
                  <td>
                    <button
                      class="btn btn-primary f-13"
                      @click.prevent="unarchivedUser(user.id)"
                    >
                      Unarchive
                    </button>
                  </td>
                </tr>
              </tbody>
              <NoData
                v-else
                :data="{ colspan: 3, message: 'No achieved users' }"
              ></NoData>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NoData from "@/components/dashboard/noData.vue";
export default {
  components: { NoData },
  data() {
    return {
      users: [],
      loaded: false,
    };
  },
  methods: {
    unarchivedUser(id) {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "users/restore/" + this.$store.state.user.id,
          details: { user_id: id },
        })
        .then(() => {
          window.SweetAlert("success", "User unarchived");
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        });
    },
    getUsers() {
      this.$store
        .dispatch("get", "users/deleted/all/" + this.$store.state.user.id)
        .then((resp) => {
          console.log(resp);
          this.users = resp.data;
          this.loaded = true;
        });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style scoped lang="scss">
.cancR {
  flex-direction: column;
  align-items: center;
  i {
    font-size: 60px;
    color: #dc3545;
  }
}
label {
  margin-bottom: 5px;
}
.appv {
  color: #198754;
  background: #1987545c;
  padding: 5px 9px;
}
.cancv {
  padding: 5px 10px;
}
.table .btn {
  border: none;
}
table tbody tr td {
  padding: 5px 16px !important;
}
</style>
