<script>
import SalesCard from "../widgets/SalesCard.vue";

export default {
  name: "online-stores",
  components: { SalesCard },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <h6 class="pb-2 border-bottom border-theme mb-4">Cable TV</h6>

    <div class="p-2">
      <div class="row row-gap-3">
        <div class="col-6 col-xl-3">
          <sales-card image-text="Jumia" route-name="jumia" image-url="/assets/img/requests/jumia.svg" contain />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card image-text="Konga" route-name="konga" image-url="/assets/img/requests/konga.svg" />
        </div>
      </div>
    </div>
  </div>
</template>
