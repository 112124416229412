import { render, staticRenderFns } from "./generalSettings.vue?vue&type=template&id=61d514ab&scoped=true"
var script = {}
import style0 from "./generalSettings.vue?vue&type=style&index=0&id=61d514ab&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61d514ab",
  null
  
)

export default component.exports