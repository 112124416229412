<template>
  <div class="merchants-list row">
    <div class="col-sm-3 mer-logo-con">
      <button class="m-logo" @click="redirect('jumia')">
        <div class="img-con">
          <img src="@/assets/img/jumia.png" class="h-100 w-100" />
        </div>
        <!-- <span>jumia</span> -->
      </button>
    </div>
    <div class="col-sm-3 mer-logo-con">
      <button class="m-logo" @click="redirect('konga')">
        <div class="img-con">
          <img src="@/assets/img/konga.png" class="h-100 w-100" />
        </div>
        <!-- <span>konga</span> -->
      </button>
    </div>
    <div class="col-sm-3 mer-logo-con d-flex flex-column">
      <a href="/bills" class="off-pay m-logo py-2">
        <span>Airtime</span>
      </a>
      <div class="airtime">
        <img src="@/assets/img/airtime.png" />
      </div>
    </div>
    <!-- <div class="col-sm-3 mer-logo-con">
      <button class="m-logo" @click="redirect('xerox')">
        <div class="img-con">
          <img src="@/assets/img/xerox.png" class="h-100 w-100" />
        </div>
        <span>Xerox</span>
      </button>
    </div> -->
    <!-- <div class="col-sm-3 mer-logo-con">
      <button
        class="m-logo upcomng-con"
        disabled
        @click="redirect('amazon')"
      >
        <div class="img-con">
          <img src="@/assets/img/amazon.png" class="h-100 w-100" />
        </div>
      </button>
      <p class="upcoming">Coming soon</p>
    </div>
    <div class="col-sm-3 mer-logo-con">
      <button
        class="m-logo upcomng-con"
        disabled
        @click="redirect('aliexpress')"
      >
        <div class="img-con">
          <img src="@/assets/img/aliexpress.png" class="h-100 w-100" />
        </div>
      </button>
      <p class="upcoming">Coming soon</p>
    </div> -->
  </div>
</template>

<script>
export default {
  props: { dashboard: { type: Boolean, default: false } },
  methods: {
    async redirect(merchant) {
      window.$("#merchantsModal").modal("hide");
      this.redirectToMerchantsPage(merchant);
      // var formdata = new FormData();
      // if (merchant == "amazon") {
      //   this.$store.commit("setLoader", true);
      //   formdata.append("email", this.$store.state.user.email);
      //   this.$store
      //     .dispatch("post", {
      //       endpoint: "punchout/amazon/start/" + this.$store.state.user.email,
      //     })
      //     .then((resp) => {
      //       this.$store.commit("setLoader", false);
      //       window.open(resp.data);
      //     })
      //     .catch((err) => {
      //       this.$store.commit("setLoader", false);
      //       this.$store.dispatch("handleError", err);
      //     });
      // } else if (merchant == "jumia") {
      //   window.location = "jumia";
      // } else if (merchant == "konga") {
      //   window.location = "konga";
      // } else if (merchant == "xerox") {
      //   window.location = "xerox";
      // }
    },
  },
};
</script>

<style scoped lang="scss">
.merchants-list {
  .airtime {
    display: block;
    text-align: center;
    bottom: 39px;
    margin-left: 58px;
    position: absolute;
  }
  .airtime img {
    width: 50px;
  }
  .req-pay {
    background: #022438 !important;
    color: #fff;
  }
  .off-pay {
    border: 1px solid #022438 !important;
    color: #022438;
  }
  .req-pay,
  .off-pay {
    padding: 27px 15px !important;
  }
  .upcomng-con {
    background: gray !important;

    opacity: 0.2;
  }
  .upcoming {
    // position: absolute;
    margin-top: -84px;
    text-align: center;
    background: #fff;
    padding: 11px 20px;
    width: 100%;
    // margin-right: 10px;
    // margin-bottom: 10px;
  }
  .m-logo {
    // border: 1px solid gray;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 10px;
    width: 100%;
    background-color: transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 15px;
    display: block;
    text-align: center;
    .img-con {
      height: 60px;
      img {
        object-fit: contain;
      }
    }

    span {
      font-size: 22px;
      font-weight: bold;
    }
  }
}
@media (max-width: 576px) {
  .merchants-list .upcomng-con {
    margin-bottom: 34px !important;
  }
}
</style>
