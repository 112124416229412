<script>
  import SalesCard from "../widgets/SalesCard.vue";

  export default {
    name: "data-purchase",
    components: { SalesCard },
    data() {
      return {
        setTrue: true,
      };
    },
  };
</script>

<template>
  <div>
    <h6 class="pb-2 border-bottom border-theme mb-4">Data</h6>

    <div
      class="border border-theme px-2 py-1 rounded-4 d-flex flex-wrap justify-content-start align-items-center"
    >
      <span class="fs-8 text-theme-100 me-1">Sold by:</span>
      <div style="width: 70px; height: 30px; position: relative">
        <img
          src="/assets/img/requests/oneaccess.png"
          class="w-100 h-100 object-contain"
          alt="oneaccess.png"
        />
      </div>
    </div>

    <div class="p-2 pb-4 mb-4 border-bottom border-theme">
      <div class="row row-gap-3">
        <div class="col-6 col-xl-3">
          <sales-card
            :route-name="'purchase.data-purchase'"
            image-text="9Mobile data plan"
            image-url="/assets/img/requests/9mobile.svg"
            query="9mobile-sme-data"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="airtel data plan"
            :route-name="'purchase.data-purchase'"
            image-url="/assets/img/requests/airtel.svg"
            query="airtel-data"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="glo data plan"
            image-url="/assets/img/requests/glo.svg"
            :route-name="'purchase.data-purchase'"
            query="glo-data"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="mtn data plan"
            image-url="/assets/img/requests/mtn.svg"
            :route-name="'purchase.data-purchase'"
            query="mtn-data"
            contain
          />
        </div>
      </div>
    </div>

    <div
      class="border border-theme px-2 py-1 rounded-4 d-flex flex-wrap justify-content-start align-items-center"
    >
      <span class="fs-8 text-theme-100 me-1">Fulfilled by:</span>
      <div style="width: 70px; height: 30px; position: relative">
        <img
          src="/assets/img/logo-2.png"
          class="w-100 h-100 object-contain"
          alt="oneaccess.png"
        />
      </div>
    </div>

    <div class="p-2">
      <div class="row row-gap-3">
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="Smile Internet Data"
            :route-name="'purchase.smile-data-purchase'"
            image-url="/assets/img/requests/smile.png"
            query="smile-direct"
            :hasRouteTitle="setTrue"
            title="Smile Data Purchase"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="spectranet"
            image-url="/assets/img/requests/spectranet.png"
            :route-name="'purchase.spectranet-data-purchase'"
            :hasRouteTitle="setTrue"
            title="Spectranet Data Purchase"
            contain
          />
        </div>
      </div>
    </div>
  </div>
</template>
