<template>
  <div class="orders" v-if="loaded">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <h5 class="card-title mb-0">Purchases</h5>
      <div>
        <router-link to="/requests/purchase" class="btn btn-primary btn-add">
          <!-- <i class="fas fa-plus me-2"></i>  -->
          Purchase Request
        </router-link>
        <button class="btn btn-primary btn-add ms-2" @click="exportToExcel()" :disabled="orderExportLoading">
          <i class="fas fa-file-excel me-2"></i>
          <span v-if="!orderExportLoading">Export</span>
          <span v-else>Loading...</span>
        </button>
      </div>
    </div>
    <div class="orders-body mt-3">
      <div class="card b-card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="tb-sn"></th>
                  <th>Purchase No</th>
                  <th>Requester</th>
                  <th>Business purpose</th>
                  <th>Supplier</th>
                  <th>Total</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody v-if="orders.orders.length > 0">
                <tr v-for="(order, index) in orders.orders" :key="index">
                  <td>
                    <router-link :to="'order/' + order.order_id" class="btn-view" v-if="order.merchant == 'Konga' || order.merchant == 'Jumia'">
                      <i class="fas fa-eye"></i>
                    </router-link>
                    <router-link v-else :to="'bill/' + order.order_id" class="btn-view">
                      <i class="fas fa-eye"></i>
                    </router-link>
                  </td>

                  <td>{{ order.order_no }}</td>
                  <td>{{ order.order_by_name }}</td>
                  <td>
                    {{ order.business_purpose ? order.business_purpose.slice(0, 50) : '' }}
                    {{ order.business_purpose ? (order.business_purpose.length > 50 ? '...' : '') : '' }}
                  </td>
                  <td>
                    <span style="text-transform: capitalize"> {{ order.merchant ? order.merchant.replace(/-/g, ' ') : 'Airtime' }}</span>
                  </td>
                  <td>{{ order.merchant == 'Amazon' ? '$' : '₦' }}{{ formatPrice(order.total) }}</td>
                  <td>
                    {{ formatDateTime(order.date) }}
                  </td>
                  <td>
                    <span class="badge badge-primary" v-if="order.approval == 0">Approvals Pending</span>
                    <span class="badge badge-success" v-else-if="order.approval == 1">Approved</span>
                    <span class="badge badge-danger" v-else-if="order.approval == 2">Declined</span>
                    <span class="badge badge-primary" v-else-if="order.approval == 3">Draft</span>
                    <span class="badge badge-info" v-else-if="order.approval == 4">Awaiting funds</span>
                    <span class="badge badge-success" v-else-if="order.approval == 5">Processed</span>
                    <span class="badge badge-primary" v-else-if="order.approval == 6">Delivery in progress</span>
                    <span class="badge badge-success" v-else-if="order.approval == 7">Delivered</span>
                    <span class="badge badge-info" v-else-if="order.approval == 8">Refund ongoing</span>
                    <span class="badge badge-success" v-else-if="order.approval == 9">Refunded</span>
                    <span class="badge badge-danger" v-else-if="order.approval == 10">Deleted</span>
                    <span class="badge badge-success" v-else-if="order.approval == 12">Fully Delivered</span>
                    <span class="badge badge-success" v-else-if="order.approval == 13">Paid</span>
                    <span class="badge badge-primary" v-else>unknown</span>
                  </td>
                </tr>
              </tbody>
              <NoData :data="{ colspan: 9, message: 'No available order' }" v-else></NoData>
            </table>
          </div>
          <nav aria-label="Page navigation">
            <ul class="pagination justify-content-end">
              <li class="page-item" v-if="pagination.current != 1" :class="pagination.current == 1 ? 'disabled' : ''">
                <a class="page-link" href="#" @click.prevent="getOrders(pagination.current - 1)" tabindex="-1" aria-disabled="true">Previous</a>
              </li>
              <li class="page-item" v-for="(item, index) in pagination.pagLimit" :key="index" :class="item == pagination.current ? 'active' : ''">
                <a v-if="item <= pagination.last" class="page-link" @click.prevent="getOrders(item)" href="#">{{ item }}</a>
              </li>
              <li class="page-item" v-if="pagination.last > pagination.pagLimit">
                <select @change="nextPage($event)" class="select-page-link" :class="pagination.current > pagination.pagLimit ? 'active' : ''">
                  <option value="">...</option>
                  <option :value="item" v-for="(item, index) in pagination.last - pagination.pagLimit" :key="index">
                    {{ item + pagination.pagLimit }}
                  </option>
                </select>
              </li>
              <li class="page-item" v-if="pagination.current != pagination.last">
                <a class="page-link" @click.prevent="getOrders(pagination.current + 1)" href="#">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="modal fade" id="merchantsModal" tabindex="-1" aria-labelledby="merchantsModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="merchantsModalLabel">Purchase Request</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <merchants />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import merchants from '@/components/dashboard/merchants.vue';
import NoData from '@/components/dashboard/noData.vue';
import axios from 'axios';
export default {
  components: { merchants, NoData },
  data() {
    return {
      amazon: {
        url: process.env.VUE_APP_PHP_PUNCHOUT_URL,
      },
      loading: false,
      orders: [],
      loaded: false,
      orderExportLoading: false,
      pagination: {
        last: 2,
        current: 1,
        pagLimit: 6,
      },
    };
  },
  methods: {
    exportToExcel() {
      this.orderExportLoading = true;
      axios.get(this.$store.state.endpoint + 'company/report/orders/' + this.$store.state.user.user_id, { responseType: 'blob' }).then((res) => {
        this.orderExportLoading = false;
        var FILE = window.URL.createObjectURL(new Blob([res.data]));

        var docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', 'orders.xlsx');
        window.document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    nextPage(no) {
      var cal = Number(no.target.value) + Number(this.pagination.pagLimit);
      this.getOrders(cal);
    },
    getOrders(page = 1) {
      this.$store.commit('setLoader', true);
      this.$store.dispatch('get', `orders/company/all/${this.$store.state.user.id}?page=${page}`).then((resp) => {
        console.log(resp);
        this.$store.commit('setLoader', false);
        this.loaded = true;
        this.orders = resp.data;
        this.pagination.current = page;
        this.pagination.last = Math.ceil(this.orders.total / this.orders.records_per_page);
        this.loaded = true;
      });
    },
  },
  created() {
    this.getOrders();
  },
};
</script>

<style scoped lang="scss">
.card-title {
  font-size: 18px;
}
.btn-add {
  font-size: 14px;
  border-radius: 0;
}
</style>
