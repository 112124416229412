<template>
  <div class="approval-circle">
    <div class="main-header">
      <div class="header-title">
        <h6 class="mtitle">Approval Circle</h6>
        <p class="sub-main-title">
          Approval Circles are the way to manage approval limits within your
          organization.
        </p>
      </div>
      <div>
        <button
          class="btn btn-primary f-13 ac-btn"
          :disabled="!approvalStatus"
          :class="approvalStatus ? 'active' : 'disable'"
          data-bs-toggle="modal"
          data-bs-target="#createApprovalModal"
        >
          New Approval Circle
        </button>
      </div>
    </div>
    <div class="ac-body">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckDefault"
          v-model="approvalStatus"
          @change="toggleApproval()"
        />
        <label class="form-check-label f-14" for="flexCheckDefault">
          Enable Approval Circles
        </label>
      </div>
      <div class="card mt-3" v-if="!approvalStatus">
        <div class="card-body">
          <h6 class="ac-note">NOTE:</h6>

          <p class="ac-text1">
            1. Without enabling Approval Circles, every approver has an infinite
            limit.
          </p>
          <p class="ac-text1">2. Upon Enabling Approval Circles:</p>
          <div class="ac-note-sub ms-2">
            <p class="ac-text1">
              i. Three Circles are automatically created: "Limitless",
              "Unassigned", and "Offline". These circles cannot be deleted.
            </p>
            <p class="ac-text1">
              ii. Administrators who are also approvers are automatically added
              to the "Limitless" Circle, other approvers are initially assigned
              to the "Unassigned" Circle, while all approvers are automatically
              added to the "Offline" Circle
            </p>
            <p class="ac-text1">
              iii. If there is a sole approver. Such will be assigned to
              Limitless also
            </p>
          </div>
          <p class="ac-text1">
            3. To assign new limits, create a Circle by clicking on "New
            Approval Circle," set the limits, and add approvers to the new
            circle.
          </p>
          <p class="ac-text1">
            4. You have the flexibility to edit the approvers in the "Limitless"
            Circle. However, there must always be at least one approver in the
            "Limitless" Circle with an unlimited approval limit.
          </p>
          <p class="ac-text1">
            5. Any newly enabled approver must be assigned to a Circle before
            they are able to approve a request.
          </p>
        </div>
      </div>
      <div class="card b-card mt-3" v-else>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Limits</th>
                <th>Approvers</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in approverCircles" :key="index">
                <td>
                  <div class="ac-ttxt">
                    <h5>{{ item.title }}</h5>
                    <p>{{ item.description }}</p>
                  </div>
                </td>
                <td>
                  <span v-if="item.type == 1">
                    ₦{{ formatPrice(item.limit) }}</span
                  >
                </td>
                <td>
                  <ul>
                    <li v-for="(item2, index2) in item.approvers" :key="index2">
                      <span v-if="item2.first_name"
                        >{{ item2.first_name }} {{ item2.last_name }}</span
                      >
                      <span v-else>{{ item2.EXECUTOR_ID }}</span>
                    </li>
                  </ul>
                </td>
                <td>
                  <div class="action-div">
                    <button
                      @click="setCircle(item)"
                      class="btn btn-primary-outline"
                      data-bs-toggle="modal"
                      data-bs-target="#editCircleModal"
                      v-if="item.title != 'Unassigned' || item.id != 24"
                    >
                      Edit
                    </button>
                    <button
                      class="btn btn-danger-outline ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      v-if="item.type == 1"
                      @click="setDeleteCircle(item)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
              <tr
                v-for="(item, index) in offlineApproverCircles"
                :key="index + 'a'"
              >
                <td>
                  <div class="ac-ttxt">
                    <h5>{{ item.title }}</h5>
                    <p>{{ item.description }}</p>
                  </div>
                </td>
                <td>
                  <span v-if="item.type == 1">
                    ₦{{ formatPrice(item.limit) }}
                  </span>
                </td>
                <td>
                  <ul>
                    <li v-for="(item2, index2) in item.approvers" :key="index2">
                      {{ item2.first_name }} {{ item2.last_name }}
                    </li>
                  </ul>
                </td>
                <td>
                  <div class="action-div">
                    <button
                      @click="setCircle(item)"
                      class="btn btn-primary-outline"
                      data-bs-toggle="modal"
                      data-bs-target="#editCircleModal"
                    >
                      Edit
                    </button>
                    <button
                      class="btn btn-danger-outline ms-2"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteModal"
                      v-if="item.type == 1"
                      @click="setDeleteCircle(item)"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-custom"
      id="editCircleModal"
      tabindex="-1"
      aria-labelledby="editCircleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <rect
                  x="1"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  fill="white"
                />
                <path
                  d="M25.5 15L15.5 25M15.5 15L25.5 25"
                  stroke="#6A6A6A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="1"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#DFDFDF"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header">
              <div class="d-flex align-items-center">
                <div class="add-i">
                  <i class="fa fa-edit"></i>
                </div>
                <h4 class="mb-0 ms-2">Edit Approval Circle (Limit) details</h4>
              </div>
              <p class="mh-sub">
                Fill in the details below to edit the details of the approval
                circle and click on the update button to proceed
              </p>
            </div>
            <div class="form">
              <form>
                <div class="form-group">
                  <label for="">Name <span class="required">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="editCircle.title"
                    placeholder="Enter a name for the approval circle"
                    :disabled="editCircle.type == 0 ? true : false"
                  />
                </div>
                <div class="form-group">
                  <label for="">Description</label>
                  <textarea
                    class="form-control"
                    id=""
                    cols="10"
                    rows="4"
                    v-model="editCircle.description"
                    placeholder="Describe the people in the circle"
                    :disabled="editCircle.type == 0 ? true : false"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label for="">Limit <span class="required">*</span></label>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">₦</span>
                    <input
                      v-model="editCircle.limit"
                      type="text"
                      class="form-control"
                      placeholder="Enter approval limit"
                      aria-describedby="basic-addon1"
                      :disabled="editCircle.type == 0 ? true : false"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Approvers</label>
                  <div class="d-flex">
                    <multiselect
                      v-model="approver"
                      :options="approvers"
                      placeholder="Select approver(s)"
                      :show-labels="false"
                      :custom-label="firstLastNameOrEmail"
                      label="first_name"
                    >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span
                            class="option__title"
                            v-if="props.option.first_name"
                            >{{ props.option.first_name }}
                            {{ props.option.last_name }}</span
                          >
                          <span v-else>
                            {{ props.option.email }}
                          </span>
                        </div>
                      </template>
                    </multiselect>
                    <button
                      class="btn btn-primary atc px-0 ms-2"
                      @click.prevent="appendEditApprover()"
                    >
                      Add to Circle
                    </button>
                  </div>
                </div>
                <div class="ac-list" v-if="editCircle.approvers.length > 0">
                  <div
                    class="ac-item"
                    v-for="(item, index) in editCircle.approvers"
                    :key="index"
                  >
                    <p>
                      <span v-if="item.first_name"
                        >{{ item.first_name }} {{ item.last_name }}</span
                      >
                      <span v-else>{{ item.email }}</span>
                    </p>
                    <svg
                      @click="removeEditApprover(item)"
                      id="Layer_1"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                    >
                      <path
                        d="m25.78 7-1.63 20.16a2 2 0 0 1 -2 1.84h-12.31a2 2 0 0 1 -2-1.84l-1.62-20.16z"
                        fill="#ebe9f0"
                        style="fill: rgb(255, 255, 255)"
                      ></path>
                      <path
                        d="m27 6.25h-5.25v-1.25a2.75 2.75 0 0 0 -2.75-2.75h-6a2.75 2.75 0 0 0 -2.75 2.75v1.25h-5.25a.75.75 0 0 0 0 1.5h.53l1.57 19.47a2.77 2.77 0 0 0 2.74 2.53h12.32a2.77 2.77 0 0 0 2.74-2.53l1.57-19.47h.53a.75.75 0 0 0 0-1.5zm-15.25-1.25a1.25 1.25 0 0 1 1.25-1.25h6a1.25 1.25 0 0 1 1.25 1.25v1.25h-8.5zm11.65 22.1a1.26 1.26 0 0 1 -1.25 1.15h-12.31a1.26 1.26 0 0 1 -1.24-1.15l-1.6-19.35h18zm-2.65-14.1v10a.75.75 0 0 1 -1.5 0v-10a.75.75 0 0 1 1.5 0zm-4 0v10a.75.75 0 0 1 -1.5 0v-10a.75.75 0 0 1 1.5 0zm-4 0v10a.75.75 0 0 1 -1.5 0v-10a.75.75 0 0 1 1.5 0z"
                        fill="#000000"
                        style="fill: rgb(208, 22, 22)"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="form-group mt-4">
                  <button
                    class="btn btn-primary"
                    @click.prevent="updateApproverCircle()"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-custom"
      id="createApprovalModal"
      tabindex="-1"
      aria-labelledby="createApprovalModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <rect
                  x="1"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  fill="white"
                />
                <path
                  d="M25.5 15L15.5 25M15.5 15L25.5 25"
                  stroke="#6A6A6A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="1"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#DFDFDF"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header">
              <div class="d-flex align-items-center">
                <div class="add-i">
                  <i class="fa fa-plus"></i>
                </div>
                <h4 class="mb-0 ms-2">Create New Approval Circle (Limit)</h4>
              </div>
              <p class="mh-sub">
                Mandatory information to create a new Approval Circle
              </p>
            </div>
            <div class="form">
              <form>
                <div class="form-group">
                  <label for="">Name <span class="required">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="approverCircle.title"
                    placeholder="Enter a name for the approval circle"
                  />
                </div>
                <div class="form-group">
                  <label for="">Description</label>
                  <textarea
                    class="form-control"
                    id=""
                    cols="10"
                    rows="4"
                    v-model="approverCircle.description"
                    placeholder="Describe the people in the circle"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label for="">Limit <span class="required">*</span></label>
                  <div class="input-group mb-3">
                    <span class="input-group-text" id="basic-addon1">₦</span>
                    <input
                      type="text"
                      class="form-control"
                      v-model="approverCircle.limit"
                      placeholder="Enter approval limit"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="">Approvers</label>
                  <div class="d-flex">
                    <multiselect
                      v-model="approver"
                      :options="approversCopy"
                      placeholder="Select approver(s)"
                      :show-labels="false"
                      :custom-label="firstLastNameOrEmail"
                      label="first_name"
                    >
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span
                            class="option__title"
                            v-if="props.option.first_name"
                            >{{ props.option.first_name }}
                            {{ props.option.last_name }}</span
                          >
                          <span v-else>
                            {{ props.option.email }}
                          </span>
                        </div>
                      </template>
                    </multiselect>
                    <button
                      class="btn btn-primary atc px-0 ms-2"
                      @click.prevent="appendApprover()"
                    >
                      Add to Circle
                    </button>
                  </div>
                </div>
                <div class="ac-list" v-if="approverCircle.approvers.length > 0">
                  <div
                    class="ac-item"
                    v-for="(item, index) in approverCircle.approvers"
                    :key="index"
                  >
                    <p>{{ item.first_name }} {{ item.last_name }}</p>
                    <svg
                      id="Layer_1"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      @click="removeApprover(item)"
                    >
                      <path
                        d="m25.78 7-1.63 20.16a2 2 0 0 1 -2 1.84h-12.31a2 2 0 0 1 -2-1.84l-1.62-20.16z"
                        fill="#ebe9f0"
                        style="fill: rgb(255, 255, 255)"
                      ></path>
                      <path
                        d="m27 6.25h-5.25v-1.25a2.75 2.75 0 0 0 -2.75-2.75h-6a2.75 2.75 0 0 0 -2.75 2.75v1.25h-5.25a.75.75 0 0 0 0 1.5h.53l1.57 19.47a2.77 2.77 0 0 0 2.74 2.53h12.32a2.77 2.77 0 0 0 2.74-2.53l1.57-19.47h.53a.75.75 0 0 0 0-1.5zm-15.25-1.25a1.25 1.25 0 0 1 1.25-1.25h6a1.25 1.25 0 0 1 1.25 1.25v1.25h-8.5zm11.65 22.1a1.26 1.26 0 0 1 -1.25 1.15h-12.31a1.26 1.26 0 0 1 -1.24-1.15l-1.6-19.35h18zm-2.65-14.1v10a.75.75 0 0 1 -1.5 0v-10a.75.75 0 0 1 1.5 0zm-4 0v10a.75.75 0 0 1 -1.5 0v-10a.75.75 0 0 1 1.5 0zm-4 0v10a.75.75 0 0 1 -1.5 0v-10a.75.75 0 0 1 1.5 0z"
                        fill="#000000"
                        style="fill: rgb(208, 22, 22)"
                      ></path>
                    </svg>
                  </div>
                </div>
                <div class="form-group mt-4">
                  <button
                    class="btn btn-primary cac"
                    @click.prevent="createApproverCircle()"
                  >
                    Create Approval Circle
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-custom"
      id="deleteModal"
      tabindex="-1"
      aria-labelledby="deleteModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <rect
                  x="1"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  fill="white"
                />
                <path
                  d="M25.5 15L15.5 25M15.5 15L25.5 25"
                  stroke="#6A6A6A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="1"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#DFDFDF"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <path
                  d="M20.5002 3.33334C29.7052 3.33334 37.1668 10.795 37.1668 20C37.1668 29.205 29.7052 36.6667 20.5002 36.6667C11.2952 36.6667 3.8335 29.205 3.8335 20C3.8335 10.795 11.2952 3.33334 20.5002 3.33334ZM20.5002 26.6667C20.0581 26.6667 19.6342 26.8423 19.3217 27.1548C19.0091 27.4674 18.8335 27.8913 18.8335 28.3333C18.8335 28.7754 19.0091 29.1993 19.3217 29.5119C19.6342 29.8244 20.0581 30 20.5002 30C20.9422 30 21.3661 29.8244 21.6787 29.5119C21.9912 29.1993 22.1668 28.7754 22.1668 28.3333C22.1668 27.8913 21.9912 27.4674 21.6787 27.1548C21.3661 26.8423 20.9422 26.6667 20.5002 26.6667ZM20.5002 10.8333C18.8978 10.8333 17.3611 11.4699 16.2281 12.6029C15.095 13.7359 14.4585 15.2727 14.4585 16.875C14.4585 17.317 14.6341 17.741 14.9467 18.0535C15.2592 18.3661 15.6831 18.5417 16.1252 18.5417C16.5672 18.5417 16.9911 18.3661 17.3037 18.0535C17.6162 17.741 17.7918 17.317 17.7918 16.875C17.7924 16.3834 17.9267 15.9012 18.1805 15.4802C18.4342 15.0591 18.7978 14.715 19.2322 14.4849C19.6666 14.2547 20.1554 14.1471 20.6463 14.1737C21.1372 14.2002 21.6116 14.3598 22.0187 14.6355C22.4257 14.9111 22.7501 15.2923 22.957 15.7383C23.1639 16.1842 23.2455 16.6781 23.1931 17.1669C23.1406 17.6557 22.9561 18.121 22.6594 18.513C22.3626 18.9049 21.9648 19.2087 21.5085 19.3917C20.3818 19.8417 18.8335 20.995 18.8335 22.9167V23.3333C18.8335 23.7754 19.0091 24.1993 19.3217 24.5119C19.6342 24.8244 20.0581 25 20.5002 25C20.9422 25 21.3661 24.8244 21.6787 24.5119C21.9912 24.1993 22.1668 23.7754 22.1668 23.3333C22.1668 22.9267 22.2502 22.7233 22.6018 22.55L22.7468 22.4833C24.0482 21.9598 25.1269 20.9999 25.7981 19.7682C26.4692 18.5365 26.691 17.1097 26.4254 15.7323C26.1598 14.355 25.4233 13.1129 24.3422 12.2191C23.2612 11.3253 21.9029 10.8354 20.5002 10.8333Z"
                  fill="#dc3646"
                />
              </svg>
              <h4 class="mb-0 ms-2">Delete Approval Circle</h4>
            </div>
            <div class="d-flex flex-column pop-up">
              <p class="mt-4 mb-3 text-muted">
                Deleting this approval circle brings every approver back to
                infinite limits
              </p>
              <p class="text-primary mb-5">
                Are you sure you want to continue?
              </p>
              <button
                class="btn btn-primary disable-btn"
                @click="deleteApproverCirle()"
              >
                Delete Approval Circle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade modal-custom"
      id="disableCircleModal"
      tabindex="-1"
      aria-labelledby="disableCircleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <rect
                  x="1"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  fill="white"
                />
                <path
                  d="M25.5 15L15.5 25M15.5 15L25.5 25"
                  stroke="#6A6A6A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="1"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="19.5"
                  stroke="#DFDFDF"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body">
            <div class="mcustom-header d-flex align-items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
              >
                <path
                  d="M20.5002 3.33334C29.7052 3.33334 37.1668 10.795 37.1668 20C37.1668 29.205 29.7052 36.6667 20.5002 36.6667C11.2952 36.6667 3.8335 29.205 3.8335 20C3.8335 10.795 11.2952 3.33334 20.5002 3.33334ZM20.5002 26.6667C20.0581 26.6667 19.6342 26.8423 19.3217 27.1548C19.0091 27.4674 18.8335 27.8913 18.8335 28.3333C18.8335 28.7754 19.0091 29.1993 19.3217 29.5119C19.6342 29.8244 20.0581 30 20.5002 30C20.9422 30 21.3661 29.8244 21.6787 29.5119C21.9912 29.1993 22.1668 28.7754 22.1668 28.3333C22.1668 27.8913 21.9912 27.4674 21.6787 27.1548C21.3661 26.8423 20.9422 26.6667 20.5002 26.6667ZM20.5002 10.8333C18.8978 10.8333 17.3611 11.4699 16.2281 12.6029C15.095 13.7359 14.4585 15.2727 14.4585 16.875C14.4585 17.317 14.6341 17.741 14.9467 18.0535C15.2592 18.3661 15.6831 18.5417 16.1252 18.5417C16.5672 18.5417 16.9911 18.3661 17.3037 18.0535C17.6162 17.741 17.7918 17.317 17.7918 16.875C17.7924 16.3834 17.9267 15.9012 18.1805 15.4802C18.4342 15.0591 18.7978 14.715 19.2322 14.4849C19.6666 14.2547 20.1554 14.1471 20.6463 14.1737C21.1372 14.2002 21.6116 14.3598 22.0187 14.6355C22.4257 14.9111 22.7501 15.2923 22.957 15.7383C23.1639 16.1842 23.2455 16.6781 23.1931 17.1669C23.1406 17.6557 22.9561 18.121 22.6594 18.513C22.3626 18.9049 21.9648 19.2087 21.5085 19.3917C20.3818 19.8417 18.8335 20.995 18.8335 22.9167V23.3333C18.8335 23.7754 19.0091 24.1993 19.3217 24.5119C19.6342 24.8244 20.0581 25 20.5002 25C20.9422 25 21.3661 24.8244 21.6787 24.5119C21.9912 24.1993 22.1668 23.7754 22.1668 23.3333C22.1668 22.9267 22.2502 22.7233 22.6018 22.55L22.7468 22.4833C24.0482 21.9598 25.1269 20.9999 25.7981 19.7682C26.4692 18.5365 26.691 17.1097 26.4254 15.7323C26.1598 14.355 25.4233 13.1129 24.3422 12.2191C23.2612 11.3253 21.9029 10.8354 20.5002 10.8333Z"
                  fill="#022438"
                />
              </svg>
              <h4 class="mb-0 ms-2">Disable Approval Circles</h4>
            </div>
            <div class="d-flex flex-column pop-up">
              <p class="mt-4 mb-3 text-muted">
                Disabling the Approval Circles brings every approver back to
                infinite limits
              </p>
              <p class="text-primary mb-5">
                Are you sure you want to continue?
              </p>
              <button
                class="btn btn-primary disable-btn"
                @click="disableApproval()"
              >
                Disable Approval Circle
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      approvalSettings: {},
      approvalStatus: false,
      approvers: [],
      approversCopy: [],
      approver: {},
      approverCircles: [],
      offlineApproverCircles: [],
      approverCircle: {
        title: "",
        description: "",
        limit: 0,
        approvers: [],
      },
      editCircle: {
        title: "",
        description: "",
        limit: 0,
        approvers: [],
      },
      deleteCircle: {},
    };
  },
  methods: {
    setDeleteCircle(item) {
      this.deleteCircle = item;
      console.log(this.deleteCircle);
    },
    disableApproval() {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("put", {
          endpoint: `company/settings/${this.approvalSettings.id}/${this.$store.state.user.user_id}`,
          details: {
            preferences: {
              feesCategory: {
                id: null,
                title: "Unclassified",
              },
              approvalCircle: false,
            },
          },
        })
        .then((resp) => {
          this.$store.commit("setLoader", false);
          console.log(resp);
          window.ToasterAlert(
            "success",
            "Approval Circle disabled successfully"
          );
          window.setTimeout(function () {
            window.location.reload();
          }, 2000);
        });
    },
    deleteApproverCirle() {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("delete", {
          endpoint: `company/approvalcircle/${this.deleteCircle.id}/${this.$store.state.user.user_id}`,
        })
        .then(() => {
          this.$store.commit("setLoader", false);
          window.ToasterAlert(
            "success",
            "Approval Circle deleted successfully"
          );
          window.setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          console.log(err);
        });
    },
    appendEditApprover() {
      if (!this.approver || Object.keys(this.approver).length === 0) {
        window.ToasterAlert("error", "No approver selected");
        return false;
      }
      if (
        this.editCircle.approvers.find(
          (item) => item.EXECUTOR_ID == this.approver.EXECUTOR_ID
        )
      ) {
        window.ToasterAlert("error", "This approver is already added");
        return false;
      }

      this.editCircle.approvers.push(this.approver);
      this.approver = {};
    },
    updateApproverCircle() {
      if (this.editCircle.title.length < 1) {
        window.ToasterAlert("error", "Name field is required");
        return false;
      }
      if (this.editCircle.title.limit < 1) {
        window.ToasterAlert("error", "Limit field is required");
        return false;
      }
      // if (this.editCircle.approvers.length < 1) {
      //   window.ToasterAlert("error", "Approvers field is required");
      //   return false;
      // }

      this.$store.commit("setLoader", true);
      console.log(this.editCircle);
      this.$store
        .dispatch("put", {
          endpoint: `company/approvalcircle/${this.editCircle.id}/${this.$store.state.user.user_id}`,
          details: { ...this.editCircle },
        })
        .then((resp) => {
          this.$store.commit("setLoader", false);
          window.ToasterAlert(
            "success",
            "Approver circle updated successfully"
          );
          window.setTimeout(function () {
            window.location.reload();
          }, 2000);
          console.log(resp);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          console.log(err);
        });
    },
    setApproverCopy() {
      this.approversCopy = this.approvers;
    },
    removeApprover(copy) {
      this.approverCircle.approvers.splice(
        this.approverCircle.approvers.findIndex(
          (item) => item.EXECUTOR_ID == copy.EXECUTOR_ID
        ),
        1
      );
      this.approversCopy.push(copy);
    },
    removeEditApprover(copy) {
      this.editCircle.approvers.splice(
        this.editCircle.approvers.findIndex(
          (item) => item.EXECUTOR_ID == copy.EXECUTOR_ID
        ),
        1
      );
    },
    setCircle(item) {
      this.editCircle = item;
      console.log(this.editCircle);
    },
    firstLastNameOrEmail({ first_name, last_name, email }) {
      return first_name ? `${first_name} ${last_name}` : email;
    },
    appendApprover() {
      console.log(this.approver);
      if (!this.approver || Object.keys(this.approver).length === 0) {
        window.ToasterAlert("error", "No Approver selected");
        return false;
      }

      this.approverCircle.approvers.push(this.approver);
      this.approversCopy.splice(
        this.approversCopy.findIndex(
          (item) => item.EXECUTOR_ID == this.approver.EXECUTOR_ID
        ),
        1
      );
      this.approver = {};
    },
    createApproverCircle() {
      if (this.approverCircle.title.length < 1) {
        window.ToasterAlert("error", "Name field is required");
        return false;
      }
      if (this.approverCircle.title.limit < 1) {
        window.ToasterAlert("error", "Limit field is required");
        return false;
      }
      // if (this.approverCircle.approvers.length < 1) {
      //   window.ToasterAlert("error", "Approvers field is required");
      //   return false;
      // }
      this.$store.commit("setLoader", true);
      console.log(this.approverCircle);
      this.$store
        .dispatch("post", {
          endpoint: `company/approvalcircle/${this.$store.state.user.user_id}`,
          details: { ...this.approverCircle },
        })
        .then((resp) => {
          this.$store.commit("setLoader", false);
          window.ToasterAlert(
            "success",
            "Approver circle created successfully"
          );
          window.setTimeout(function () {
            window.location.reload();
          }, 2000);
          console.log(resp);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          console.log(err);
        });
    },
    toggleApproval() {
      console.log(this.approvalStatus);
      if (this.approvalStatus) {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch("post", {
            endpoint: `company/settings/${this.$store.state.user.user_id}`,
            details: {
              preferences: {
                feesCategory: {
                  id: null,
                  title: "Unclassified",
                },
                approvalCircle: true,
              },
            },
          })
          .then(async (resp) => {
            await this.getApprovalCircle();
            this.$store.commit("setLoader", false);
            console.log(resp);
          })
          .catch((err) => {
            this.$store.commit("setLoader", false);
            console.log(err);
            this.$store.dispatch("handleError", err);
          });
      } else {
        this.approvalStatus = true;
        console.log("ooii");
        window.$("#disableCircleModal").modal("show");
      }
    },
    async getApprovalCircle() {
      this.$store.commit("setLoader", true);
      await this.$store
        .dispatch(
          "get",
          `company/approvalcircle/${this.$store.state.user.user_id}`
        )
        .then((resp) => {
          this.$store.commit("setLoader", false);
          console.log(resp);
          this.approverCircles = resp.data;
        });
    },
    async getOfflineApprovalCircle() {
      this.$store.commit("setLoader", true);
      await this.$store
        .dispatch(
          "get",
          `company/approvalcircleoffline/${this.$store.state.user.user_id}`
        )
        .then((resp) => {
          this.$store.commit("setLoader", false);
          console.log(resp);
          this.offlineApproverCircles = resp.data;
        });
    },
    getApprovals() {
      this.$store
        .dispatch(
          "get",
          "users/approvers/read/" + this.$store.state.user.user_id
        )
        .then((resp) => {
          console.log(resp);
          this.approvers = resp.data;
          this.approvers.sort((a, b) =>
            a.first_name.localeCompare(b.first_name)
          );
          this.setApproverCopy();
        });
    },
    checkApprovalCircleStatus() {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("get", `company/settings/${this.$store.state.user.user_id}`)
        .then(async (resp) => {
          console.log(resp);
          this.approvalSettings = resp.data;
          this.approvalStatus = resp.data.approvalCircle;
          if (this.approvalStatus) {
            await this.getApprovalCircle();
            await this.getOfflineApprovalCircle();
          }
          this.$store.commit("setLoader", false);
        })
        .catch(() => {
          this.$store.commit("setLoader", false);
        });
    },
  },
  created() {
    // this.disableApproval();
    this.checkApprovalCircleStatus();
    this.getApprovals();
  },
};
</script>

<style>
.modal-body .multiselect__tags {
  min-height: 47px;
}
</style>
<style scoped lang="scss">
.approval-circle {
  svg {
    cursor: pointer;
  }
  .main-header {
    .ac-btn {
      padding: 10px 20px;
    }
    .btn.disable {
      background: #ccd3d7 !important;
      color: #fff !important;
    }
  }
  .ac-body {
    margin-top: 39px;

    table thead tr th:first-child {
      padding: 17px 12px 17px 18px !important;
    }

    table tbody tr td {
      text-align: left;
      padding: 20px 12px !important;
    }
    table tbody tr td:first-child {
      width: fit-content;
      padding-left: 18px !important;
    }
    .ac-ttxt {
      p {
        margin-bottom: 0;
        max-width: 20em;
        font-size: 13px;
        color: #9f9f9f;
      }
    }
    .action-div {
      display: flex;
      justify-content: center;
      .btn {
        font-size: 13px;
        padding: 4px 20px;
        border-radius: 6px;
      }
    }
    .action-txt {
      color: #9f9f9f;
      max-width: 15em;
      text-align: center;
    }
    .ac-note {
      color: #022438;
      font-weight: bold;
      margin-bottom: 16px;
    }
    .ac-text1 {
      color: #848484;
      font-size: 14px;
    }
    .ac-note-sub {
      margin-bottom: 13px;
    }
    .ac-note-sub .ac-text1 {
      margin-bottom: 3px;
    }
  }

  .modal-body {
    ::placeholder {
      color: #f9f9f9;
    }
    ::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #fff;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #cfcfcf;
      border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #cfcfcf;
    }

    .pop-up {
      .disable-btn {
        width: fit-content;
        padding: 9px 30px;
        font-size: 15px;
        border-radius: 7px;
      }
    }

    .ac-list {
      border: 1px solid #cfcfcf;
      border-radius: 8px;
      max-height: 10em;
      padding: 12px;
      overflow-y: auto;

      .ac-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;

        p {
          color: #6a6a6a;
          font-size: 13px;
          margin-bottom: 0;
        }
        svg {
          width: 20px;
        }
      }
    }
    .cac {
      padding: 12px 24px;
      border-radius: 7px;
    }
    .atc {
      font-size: 13px;
      width: 128px;
      border-radius: 7px;
    }
    label {
      font-size: 15px;
      color: #6a6a6a;
      margin-bottom: 5px;
    }
    .form-group {
      margin-bottom: 10px;
    }
    .form-control {
      height: 47px;
      border-radius: 9px;
      font-size: 13px;
    }
    .input-group-text {
      background-color: #022438;
      color: #fff;
      padding: 0.375rem 19px;
      border-radius: 9px;
    }
    textarea.form-control {
      min-height: 90px;
    }
    .mh-sub {
      margin-top: 15px;
      color: #9f9f9f;
    }
    .add-i {
      background: #dfdfdf;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
    }
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 550px;
      margin: 1.75rem auto;
    }
  }
}
</style>
