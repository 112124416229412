<script>
  import SalesCard from "../widgets/SalesCard.vue";

  export default {
    name: "power",
    components: { SalesCard },
  };
</script>

<template>
  <div>
    <h6 class="pb-2 border-bottom border-theme mb-4">Power</h6>

    <div
      class="border border-theme px-2 py-1 rounded-4 d-flex flex-wrap justify-content-start align-items-center"
    >
      <span class="fs-8 text-theme-100 me-1">Powered by:</span>
      <div style="width: 70px; height: 30px; position: relative">
        <img
          src="/assets/img/requests/tao.svg"
          class="w-100 h-100 object-contain"
          alt=""
        />
      </div>
    </div>

    <div class="p-2">
      <div class="row row-gap-3">
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="abuja-electric"
            title="Abuja Electric"
            image-text="Abuja Electric"
            image-url="/assets/img/requests/aedc.svg"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="eko-electric"
            title="Eko Electric"
            image-text="Eko Electric"
            image-url="/assets/img/requests/ekedc.svg"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="ibadan-electric"
            title="Ibadan Electric"
            image-text="Ibadan Electric"
            image-url="/assets/img/requests/ibedc.svg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="ikeja-electric"
            title="Ikeja Electric"
            image-text="Ikeja Electric"
            image-url="/assets/img/requests/ikeja-electricity.svg"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="kano-electric"
            title="Kano Electric"
            image-text="Kano Electric"
            image-url="/assets/img/requests/kedco.svg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="portharcourt-electric"
            title="Portharcourt Electric"
            image-text="Port-Harcourt Electric"
            image-url="/assets/img/requests/phd.svg"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="jos-electric"
            title="Jos Electric"
            image-text="Jos Electric"
            image-url="/assets/img/requests/jos-electricity.svg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="kaduna-electric"
            title="Kaduna Electric"
            image-text="Kaduna Electric"
            image-url="/assets/img/requests/kedco.svg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="enugu-electric"
            title="Enugu Electric"
            image-text="Enugu Electric"
            image-url="/assets/img/requests/eedec.svg"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="benin-electric"
            title="Benin Electric"
            image-text="Benin Electric"
            image-url="/assets/img/requests/bedc.svg"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="aba-electric"
            title="Aba Electric"
            image-text="Aba Electric"
            image-url="/assets/img/requests/aedc.svg"
            contain
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            :has-route-title="true"
            :route-name="'purchase.power-purchase'"
            query="yola-electric"
            title="Yola Electric"
            image-text="Yola Electric"
            image-url="/assets/img/requests/yedc.svg"
            contain
          />
        </div>
      </div>
    </div>
  </div>
</template>
