<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid flex-nowrap px-2 px-sm-0">
      <button
        class="btn btn-default d-flex align-items-center"
        id="sidebarToggle"
      >
        <!-- <i class="fas fa-bars"></i> -->
        <img src="@/assets/img/left.svg" alt="" srcset="" />
        <div class="logo">
          <img src="@/assets/img/logo.png" class="w-100" />
        </div>
      </button>
      <div class="nav-user">
        <div class="nav-user-con justify-content-between">
          <div class="multiple-comp">
            <div class="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle mc-dd"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="far fa-building" style="font-size: 20px"></i>
                <span class="ms-2">{{ user.company }}</span>
              </button>
              <ul
                class="dropdown-menu py-0"
                aria-labelledby="dropdownMenuButton1"
              >
                <li v-for="(item, key) in companies" :key="key">
                  <a
                    class="dropdown-item"
                    href="#"
                    @click.prevent="switchCompany(item.user_id)"
                    >{{ item.company_name }}</a
                  >
                </li>
                <!-- <li><a class="dropdown-item" href="#">Folu and Sons</a></li> -->
                <li>
                  <a class="dropdown-item" href="/register?add_organization=1">
                    <i class="fa fa-plus me-2"></i>
                    Add Organization
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <div class="d-flex align-items-center">
              <!-- <router-link class="icon-tp" to="/notifications">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 22.8533C12.4199 22.8437 12.8229 22.6858 13.1377 22.4076C13.4525 22.1295 13.6587 21.7489 13.72 21.3333H10.2133C10.2763 21.7602 10.4922 22.1497 10.8209 22.4293C11.1495 22.7089 11.5685 22.8596 12 22.8533Z"
                    fill="#022438"
                  />
                  <path
                    d="M21.6733 18.5534C21.0305 17.9802 20.4676 17.3232 20 16.6C19.4895 15.6017 19.1835 14.5115 19.1 13.3934V10.1C19.0973 9.69998 19.0616 9.30086 18.9933 8.90668C18.4686 8.80179 17.9643 8.61268 17.5 8.34668C17.677 8.91658 17.7669 9.50994 17.7667 10.1067V13.4C17.8484 14.764 18.2236 16.0943 18.8667 17.3C19.3269 18.0294 19.8731 18.7008 20.4933 19.3H3.41999C4.04026 18.7008 4.58639 18.0294 5.04666 17.3C5.68973 16.0943 6.06495 14.764 6.14666 13.4V10.1C6.14315 9.32847 6.29186 8.56382 6.58429 7.84983C6.87671 7.13584 7.3071 6.48655 7.8508 5.93913C8.39451 5.3917 9.04086 4.9569 9.75284 4.65963C10.4648 4.36235 11.2284 4.20843 12 4.20668C13.1292 4.20759 14.233 4.54152 15.1733 5.16668C15.0697 4.78601 15.0115 4.39439 15 4.00002V3.58002C14.3039 3.23759 13.5561 3.01234 12.7867 2.91335V2.07335C12.7867 1.83731 12.6929 1.61093 12.526 1.44402C12.3591 1.27712 12.1327 1.18335 11.8967 1.18335C11.6606 1.18335 11.4342 1.27712 11.2673 1.44402C11.1004 1.61093 11.0067 1.83731 11.0067 2.07335V2.94668C9.2838 3.18972 7.70704 4.04785 6.56747 5.36264C5.4279 6.67743 4.80248 8.36011 4.80666 10.1V13.3934C4.72316 14.5115 4.41718 15.6017 3.90666 16.6C3.44725 17.3216 2.89346 17.9785 2.25999 18.5534C2.18888 18.6158 2.13189 18.6927 2.0928 18.7789C2.05372 18.8652 2.03345 18.9587 2.03333 19.0534V19.96C2.03333 20.1368 2.10356 20.3064 2.22859 20.4314C2.35361 20.5564 2.52318 20.6267 2.69999 20.6267H21.2333C21.4101 20.6267 21.5797 20.5564 21.7047 20.4314C21.8298 20.3064 21.9 20.1368 21.9 19.96V19.0534C21.8999 18.9587 21.8796 18.8652 21.8405 18.7789C21.8014 18.6927 21.7444 18.6158 21.6733 18.5534Z"
                    fill="#022438"
                  />
                  <path
                    d="M20 7.33335C21.841 7.33335 23.3334 5.84097 23.3334 4.00002C23.3334 2.15907 21.841 0.666687 20 0.666687C18.1591 0.666687 16.6667 2.15907 16.6667 4.00002C16.6667 5.84097 18.1591 7.33335 20 7.33335Z"
                    fill="#FF5555"
                  />
                </svg>
              </router-link> -->
              <div class="nav-user-icon dropdown">
                <h5
                  class="dropdown-toggle initial-dp m-0"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{
                    user.last_name
                      ? user.first_name[0] + user.last_name[0]
                      : "B"
                  }}
                </h5>
                <div
                  class="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <div class="name-pri">
                    <div class="np-img">
                      <img src="@/assets/img/user1.png" class="w-100" />
                    </div>
                    <div class="np-info">
                      <p class="mb-0">
                        {{ user.last_name }} {{ user.first_name }}
                      </p>
                      <div class="d-flex flex-wrap">
                        <div
                          class="role"
                          v-for="(role, index) in user.role_id"
                          :key="index"
                        >
                          <span class="text-muted" v-if="role == 4"
                            >Requester<em
                              v-if="index != user.role_id.length - 1"
                              >,</em
                            >
                          </span>
                          <span class="text-muted" v-if="role == 14"
                            >Approver<em v-if="index != user.role_id.length - 1"
                              >,</em
                            >
                          </span>
                          <span class="text-muted" v-if="role == 24"
                            >Finance<em v-if="index != user.role_id.length - 1"
                              >,</em
                            >
                          </span>
                          <span class="text-muted" v-if="role == 34"
                            >Tech<em v-if="index != user.role_id.length - 1"
                              >,</em
                            >
                          </span>
                          <span class="text-muted" v-if="role == 44"
                            >Admin<em v-if="index != user.role_id.length - 1"
                              >,</em
                            >
                          </span>
                          <span class="text-muted" v-if="role == 84"
                            >Supplier<em v-if="index != user.role_id.length - 1"
                              >,</em
                            >
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="dp-menu mt-3">
                    <router-link class="dp-link" to="/profile">
                      <i class="fa fa-cog"></i>Settings
                    </router-link>
                    <router-link class="dp-link" to="/notifications">
                      <i class="fas fa-bell"></i>Notifications</router-link
                    >
                    <a
                      class="dp-link"
                      href="javascript:void()"
                      @click.prevent="logout()"
                    >
                      <i class="fas fa-power-off"></i>Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (context) => context.user,
      isSupplier: (context) => context.isSupplier,
    }),
  },
  data() {
    return {
      companies: [],
    };
  },
  methods: {
    logout() {
      this.$store.commit("logout");
    },
    switchProfile() {},
    getMultipleCompany() {
      this.$store
        .dispatch("get", `users/companies/${this.user.user_id}`)
        .then((resp) => {
          this.companies = resp.data;
        });
    },
    switchCompany(id) {
      // console.log("switch user_id" + id);
      this.$store.dispatch("getUser", id).then((resp) => {
        var data = {
          user: resp.data.user,
          profile: resp.data.profile,
          token: this.$store.state.token,
          isSupplier: resp.data.is_supplier,
          isBuyer: resp.data.is_buyer,
        };
        this.$store.commit("setUser", data);
        window.location = "/dashboard";
      });
    },
  },
  mounted() {
    this.getMultipleCompany();
  },
};
</script>

<style scoped lang="scss">
.navbar {
  box-shadow: 0 2px 30px #9299b810;
  border-bottom: 1px solid #ececec;
  z-index: 999;
  background: #fff;
  position: fixed;
  width: 100%;
  height: 60px;

  .logo {
    width: 100px;
    margin-left: 20px;
  }
  .nav-user {
    width: calc(100% - 16rem);
    .nav-user-con {
      display: flex;
      align-items: center;
      .icon-tp {
        display: flex;
        // flex-direction: column;
        align-items: center;
        margin: 0 8px;
        border: 1px solid #ececec;
        padding: 7px;
        border-radius: 24px;
        background: #fbfbff;
        svg {
          width: 24px;
        }
      }

      svg {
        width: 20px;
      }
      .nav-user-icon {
        .dropdown-toggle::after {
          display: none;
        }
        .initial-dp {
          border: 1px solid #ececec;
          height: 28px;
          width: 28px;
          padding: 19px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fbfbff;
          font-size: 17px;
          cursor: pointer;
        }

        .dropdown-menu {
          right: 0;
          margin-top: 10px;
          left: auto;
          border: none;
          padding: 15px 10px;
          min-width: 17rem;
          box-shadow: 0 2px 8px rgb(0 0 0 / 15%);

          .name-pri {
            display: flex;
            align-items: center;
            background: #f4f5f7;
            border-radius: 5px;
            padding: 10px;

            .np-img {
              width: 39px;
            }
            .np-info {
              margin-left: 6px;
              p {
                font-size: 13px;
                text-transform: capitalize;
                line-height: 1;
                font-weight: 500;
              }
              .role {
                line-height: 1;
              }
              span {
                font-size: 12px;
              }
            }
          }
          .dp-menu {
            padding: 0 10px;
            .dp-link {
              display: block;
              color: #9299b8;
              margin-top: 15px;
              font-size: 14px;
              i {
                margin-right: 7px;
                width: 20px;
              }
            }
          }
        }
      }
      .nav-user-details {
        p {
          line-height: 8px;
          font-size: 15px;
        }
        span {
          font-size: 12px;
        }
      }
      .multiple-comp {
        .mc-dd {
          width: 17em;
          background: transparent;
          color: #6a6a6a;
          border-color: #9f9f9f;
          text-align: left;
          font-size: 14px;
          font-weight: bold;
          overflow-x: clip;
          display: flex;
          align-items: center;
          border-radius: 7px;
          span {
            display: block;
            width: 80%;
            overflow: hidden;
          }
          svg {
            height: 27px;
            color: #9f9f9f;
          }
        }
        .dropdown-toggle::after {
          position: absolute;
          right: 10px;
          top: 14px;
          border-top: 0.4em solid;
          border-right: 0.4em solid transparent;
          border-left: 0.4em solid transparent;
        }

        .dropdown-menu {
          // width: 15em;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          min-width: 24em;
        }
        .dropdown-item {
          margin-top: 0;
          padding: 10px 1rem;
          font-size: 14px;
          font-weight: 500;
          border-bottom: 1px solid #0b32751a;
          white-space: normal;
        }
      }
    }
  }
}

.navbar #sidebarToggle {
  outline: none;
  box-shadow: none;
  i {
    font-size: 22px;
  }
}
@media (max-width: 475px) {
  .nav-user {
    width: 100% !important;
  }
  .navbar .nav-user .nav-user-con .multiple-comp .mc-dd span {
    width: 64%;
  }
  .navbar .nav-user .nav-user-con {
    .icon-tp {
      margin: 0 4px;
    }
    .multiple-comp .mc-dd {
      width: 10em;
    }
    .icon-tp svg {
      width: 17px;
      height: 17px;
    }
    .nav-user-icon .initial-dp {
      height: 20px;
      width: 20px;
      padding: 16px;
      font-size: 15px;
    }
  }
  // .navbar .nav-user .nav-user-con .icon-tp svg[data-v-8f44ab0a]
}
@media (max-width: 333px) {
  .navbar .nav-user .nav-user-con {
    .icon-tp {
      display: none;
    }
  }
}
@media (max-width: 767px) {
  .navbar {
    #sidebarToggle {
      padding: 0;
    }
    .logo {
      margin-left: 7px;
    }
    .nav-user {
      width: fit-content;
    }
    .multiple-comp .mc-dd {
      width: 14em;
    }
    .multiple-comp .dropdown-menu {
      min-width: 15em;
    }
  }
}
</style>
