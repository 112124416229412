import { render, staticRenderFns } from "./transactions.vue?vue&type=template&id=2d349c92&scoped=true"
import script from "./transactions.vue?vue&type=script&lang=js"
export * from "./transactions.vue?vue&type=script&lang=js"
import style0 from "./transactions.vue?vue&type=style&index=0&id=2d349c92&prod&lang=css"
import style1 from "./transactions.vue?vue&type=style&index=1&id=2d349c92&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d349c92",
  null
  
)

export default component.exports