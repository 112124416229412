<script>
import Back from '../dashboard/widgets/Back-v2.vue';

export default {
  name: 'requestLayout',
  components: { Back },
  data() {
    return {};
  },
  methods: {
    searchCategory: () => {
      console.log('Search...: ');
    },
  },
};
</script>

<template>
  <div class="container-wrapper">
    <div class="d-flex justify-content-between flex-wrap align-items-center gap-sm-3 mb-3">
      <div class="d-flex flex-column">
        <Back />
        <h5 v-if="$route.meta?.subTitle">{{ $route.meta?.subTitle }}</h5>
        <h5 v-else-if="$route.meta?.showHeader != false">Purchase Categories</h5>

        <p v-if="$route.query?.title" class="fs-7">
          {{ $route.query?.title.replace(/-/g, ' ') }}
        </p>
      </div>

      <div v-if="$route.meta?.showHeader != false" class="d-flex justify-content-end gap-2 align-items-center flex-grow-1 flex-lg-grow-0">
        <!-- <form @submit.prevent="searchCategory" class="flex-grow-1 flex-lg-grow-0">
          <div class="input-group">
            <input
              type="text"
              class="form-control placeholder-xs"
              placeholder="Search categories"
              aria-describedby="button-addon4" />
            <div class="input-group-append">
              <button class="btn btn-primary border b-color-inherit rounded-t-l-0" type="submit">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </form>

        <button class="btn btn-outline-primary" type="button">
          <i class="fa fa-filter" aria-hidden="true"></i>
          <span>Filter</span>
        </button> -->
      </div>
    </div>

    <router-view />
  </div>
</template>
import { replace } from "core-js/fn/symbol";
