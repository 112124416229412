<template>
  <div>
    <div class="pending d-flex flex-column" v-if="loaded">
      <div class="img-con mb-4">
        <img src="@/assets/img/logo.png" class="w-100" />
      </div>
      <div class="card">
        <div class="card-body">
          <div class="icon py-4">
            <i class="fas fa-check"></i>
          </div>
          <p class="text-center p-color mt-0">Email verified</p>
          <div class="text-center">
            <router-link class="btn btn-primary px-5" :to="{ name: 'login' }"
              >Login</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loaded: true,
      };
    },
    methods: {
      verifyToken() {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch("post", {
            endpoint: "users/confirm-email",
            details: { token: this.$route.query.token },
          })
          .then((resp) => {
            this.$store.commit("setLoader", false);
            console.log(resp);
            this.loaded = true;
          })
          .catch(() => {
            this.$router.push({ name: "login" });
          });
      },
    },

    created() {
      this.verifyToken();
    },
  };
</script>

<style scoped lang="scss">
  .img-con {
    width: 200px;
  }
  .btn-primary {
    background: #0a0a0a;
    border: none;
  }
  .pending {
    min-width: 100vw;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    .card {
      width: 35em;
      padding: 30px;
    }
    .p-color {
      color: black;
    }
    .icon {
      text-align: center;
      i {
        font-size: 60px;
        color: #24b3d2;
      }
    }
  }
</style>
