<script>
export default {
  name: "theme-button",
  props: {
    text: {
      type: String,
      default: "Submit",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: "button",
    },
  },
};
</script>

<template>
  <button
    :type="type"
    class="btn btn-primary fs-8 py-2 px-3 overflow-hidden position-relative min-w-fit"
    :class="{ disabled: isLoading || !isActive }">
    <span
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center position-absolute inset w-100 h-100 bg-theme"
      style="z-index: 10">
      <img src="/assets/img/loader.svg" />
    </span>
    <span class="position-relative d-block">{{ text }}</span>
  </button>
</template>
