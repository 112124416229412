<script>
export default {};
</script>

<template>
    <button @click="$router.go(-1)" type="button" class="reset d-flex text-dark f-15 mb-2">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M9.53125 15.625L3.90625 10L9.53125 4.375M4.6875 10H16.0938"
          stroke="#022438"
          stroke-width="2.25"
          stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
      <span class="ms-2 f-12">Back</span>
    </button>
</template>
