import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixins from "./mixins";
import VPopover from "vue-js-popover";
import Hotjar from "vue-hotjar";
import VueOffline from "vue-offline";
import PrimaryButton from "./components/dashboard/widgets/PrimaryButton.vue";

Vue.config.productionTip = false;
Vue.mixin(mixins);
Vue.use(VueOffline);
Vue.use(VueCompositionAPI);
Vue.component("primary-button", PrimaryButton);
Vue.use(VPopover, { tooltip: true });
Vue.use(Hotjar, { id: "3517907", isProduction: true });

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
