<template>
  <div class="jumia px-2 py-4">
    <xerox-navbar></xerox-navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import XeroxNavbar from "@/components/dashboard/xerox/navbar";
export default {
  components: { XeroxNavbar },
};
</script>

<style></style>
