<script>
  export default {
    props: {
      imageUrl: String,
      imageText: String,
      contain: Boolean,
      routeName: String,
      query: {
        type: String,
        default: null,
      },
      hasRouteTitle: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: null,
      },
    },
  };
</script>

<template>
  <div class="border border-theme rounded-t-4 rounded-4">
    <div class="d-flex justify-content-center align-items-center py-2">
      <div style="width: 120px; height: 80px; position: relative">
        <img
          :src="imageUrl"
          class="w-100 h-100 object-cover rounded-4 mix-blend-mode-darken"
          :class="{ 'object-contain': contain }"
          alt="sales-image.png"
        />
      </div>
    </div>
    <router-link
      :to="{
        name: routeName,
        query: {
          type: query ? query : imageText.replace(/ /g, '').toLocaleUpperCase(),
          title: hasRouteTitle ? title : '',
        },
      }"
      class="btn btn-primary text-capitalize w-full border border-theme rounded-4 fs-7"
    >
      {{ imageText }}
    </router-link>
  </div>
</template>
