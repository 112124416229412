<template>
  <tbody>
    <tr>
      <td
        :colspan="data.colspan"
        style="background-color: #fafaff"
        :style="
          data.height ? { height: data.height } : { height: 'fit-content' }
        "
      >
        <div class="no-data text-center" style="padding: 15px">
          <div class="svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <path
                d="M11.58 24.684L14.2215 23.922L30.126 7.82701C30.2514 7.69846 30.3213 7.52571 30.3205 7.34609C30.3196 7.16647 30.2481 6.99439 30.1215 6.86701L29.169 5.90401C29.1074 5.84077 29.0339 5.79042 28.9527 5.7559C28.8715 5.72138 28.7842 5.70339 28.6959 5.70297C28.6077 5.70255 28.5202 5.71971 28.4387 5.75346C28.3571 5.7872 28.2831 5.83686 28.221 5.89951L12.3585 21.9525L11.5785 24.6825L11.58 24.684ZM31.0545 3.99601L32.007 4.96051C33.321 6.29101 33.333 8.43751 32.031 9.75451L15.642 26.3415L9.996 27.9675C9.65129 28.064 9.28239 28.0198 8.97024 27.8446C8.65809 27.6694 8.4282 27.3775 8.331 27.033C8.25867 26.7853 8.25763 26.5223 8.328 26.274L9.9705 20.514L26.316 3.97051C26.6268 3.65755 26.9968 3.40961 27.4045 3.24114C27.8121 3.07268 28.2492 2.98707 28.6903 2.9893C29.1313 2.99153 29.5676 3.08157 29.9735 3.25415C30.3794 3.42674 30.7469 3.67992 31.0545 3.99601ZM13.776 5.72551C14.52 5.72551 15.123 6.33601 15.123 7.08901C15.1242 7.26702 15.0903 7.44351 15.0232 7.60839C14.9561 7.77328 14.8572 7.92333 14.7321 8.04996C14.607 8.17659 14.4582 8.27733 14.2941 8.3464C14.1301 8.41547 13.954 8.45153 13.776 8.45251H8.388C6.9 8.45251 5.694 9.67351 5.694 11.178V27.537C5.694 29.043 6.9 30.264 8.388 30.264H24.552C26.04 30.264 27.2475 29.043 27.2475 27.537V22.0845C27.2475 21.3315 27.8505 20.721 28.5945 20.721C29.3385 20.721 29.9415 21.3315 29.9415 22.086V27.537C29.9415 30.549 27.528 32.991 24.552 32.991H8.388C5.412 32.991 3 30.549 3 27.537V11.178C3 8.16751 5.412 5.72551 8.388 5.72551H13.776Z"
                fill="#AFAFAF"
              />
            </svg>
            <p style="font-size: 20px; color: #afafaf" class="mt-1 mb-0">
              {{ data.message }}
            </p>
            <span style="font-size: 15px; color: #cfcfcf">{{
              data.subtitle
            }}</span>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: { data: {} },
};
</script>

<style></style>
