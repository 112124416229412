<script>
import SalesCard from '../widgets/SalesCard.vue';

export default {
  name: 'airtime',
  components: { SalesCard },
  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <h6 class="pb-2 border-bottom border-theme mb-4">Airtime</h6>

    <div class="border border-theme px-2 py-1 rounded-4 d-flex flex-wrap justify-content-start align-items-center">
      <span class="fs-8 text-theme-100 me-1">Sold by:</span>
      <div style="width: 70px; height: 30px; position: relative">
        <img src="/assets/img/requests/oneaccess.png" class="w-100 h-100 object-contain" alt="oneaccess.png" />
      </div>
    </div>

    <div class="p-2 pb-4 mb-4 border-bottom border-theme">
      <div class="row row-gap-3">
        <div class="col-6 col-xl-3">
          <sales-card :route-name="'purchase.airtime'" image-text="9Mobile" image-url="/assets/img/requests/9mobile-1.svg" contain />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card :route-name="'purchase.airtime'" image-text="airtel" image-url="/assets/img/requests/airtel-1.svg" contain />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card :route-name="'purchase.airtime'" image-text="glo" image-url="/assets/img/requests/glo-1.svg" contain />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card :route-name="'purchase.airtime'" image-text="MTN" image-url="/assets/img/requests/mtn-1.svg" contain />
        </div>
      </div>
    </div>

    <!--  <div class="border border-theme px-2 py-1 rounded-4 d-flex flex-wrap justify-content-start align-items-center">
      <span class="fs-8 text-theme-100 me-1">Fulfilled by:</span>
      <div style="width: 70px; height: 30px; position: relative">
        <img src="/assets/img/logo-2.png" class="w-100 h-100 object-contain" alt="buysimply.png" />
      </div>
    </div>

    <div class="p-2">
      <div class="row row-gap-3">
        <div class="col-6 col-xl-3">
          <sales-card
            :route-name="'purchase.smileairtime'"
            image-text="Smile airtime"
            query="smile-airtime"
            title="Smile Airtime"
            image-url="/assets/img/requests/smile.png"
            contain
          />
        </div>
      </div>
    </div> -->
  </div>
</template>
