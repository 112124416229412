<template>
  <div class="jumia px-2 py-0">
    <div class="j-wrapper row">
      <div
        class="col-6 col-sm-3 prod-wrapper"
        v-for="(product, index) in products"
        :key="index"
      >
        <a
          href="javascript:void()"
          class="product"
          :is="product.out_of_stock ? 'span' : 'a'"
        >
          <div class="prod-img">
            <img :src="product.image" alt="product image" class="w-100 h-100" />
          </div>
          <div class="prod-details pb-0">
            <p class="p-name">{{ product.name }}</p>
            <span class="p-price">₦{{ formatPrice(product.price) }}</span>
          </div>
        </a>
        <div
          v-if="product.out_of_stock"
          class="out_of_stock d-flex justify-content-center align-items-center py-2 mx-2"
        >
          <i class="fas fa-ban me-2"></i>
          Out of stock
        </div>
        <a
          href="javascript:void()"
          v-else
          @click="addToCart(product)"
          class="out_of_stock else d-flex justify-content-center align-items-center py-2 mx-2"
        >
          <i class="fas fa-shopping-cart me-2"></i>
          Add to cart
        </a>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn k-btn next-btn me-2"
          v-if="page != 1"
          @click="changePage('prev')"
        >
          Prev
          <i data-feather="chevron-left"></i>
        </button>
        <button class="btn k-btn next-btn" @click="changePage('next')">
          Next
          <i data-feather="chevron-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      products: [],
      loaded: false,
      search: "",
      disabled: true,
      page: 1,
    };
  },
  methods: {
    getProducts() {
      this.$store.commit("setLoader", true);
      this.$store.dispatch("get", "xerox/all").then((resp) => {
        console.log(resp);
        this.$store.commit("setLoader", false);
        this.loaded = true;
        this.products = resp.data;
      });
    },
    changePage(where) {
      if (where == "next") {
        this.page += 1;
      } else {
        this.page -= 1;
      }
      window.scrollTo(0, 0);
      this.getProducts(this.page);
    },
    async addToCart(product) {
      // this.$store.commit("setLoader", true);
      product.quantity = 1;
      product.data_id = product.id;
      console.log(product);
      console.log(this.$store.state.cart);
      this.$store.commit("addToCart", { data: product, merchant: "xerox" });
      // await this.$store
      //   .dispatch("get", `xerox/one/${product.link}`)
      //   .then((resp) => {
      //     single = resp.data;
      //     single.quantity = 1;
      //     this.$store.commit("addToCart", { data: single, merchant: "xerox" });
      //     this.$store.commit("setLoader", false);
      //   });
      // console.log(product);
    },
  },
  created() {
    this.search = this.$store.state.defaultSearchParam;
    this.getProducts(this.page);
  },
};
</script>

<style scoped lang="scss">
.jumia {
  .out_of_stock {
    background-color: #ededed;
    color: #b81a0a;
    font-size: 13px;
    margin-top: -55px;
    i {
      color: #b81a0a;
    }
    &.else {
      color: #fff;
      background-color: #d92231;
      i {
        color: #fff;
      }
    }
  }
  .prod-wrapper {
    margin-bottom: 25px;
    .product {
      // height: 16em;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      border-radius: 5px;
      margin-bottom: 10px;
      color: black;
      display: block;
      padding-bottom: 50px;

      .prod-img {
        height: 12em;
        img {
          object-fit: contain;
        }
      }
      .prod-details {
        padding: 10px;
      }
      .p-name {
        margin: 0;
        font-weight: 200;
        font-size: 14px;
      }
      .p-price {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .next-btn {
    display: flex;
    align-items: center;
    background: #d92231;
    color: #fff;
    svg {
      width: 20px;
    }
    &:hover {
      background: #d9223145;
      color: #d92231;
      font-weight: 500;
    }
  }
}
</style>
