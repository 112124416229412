<template>
  <div class="product-view" v-if="loaded">
    <div class="row">
      <div class="col-sm-8 col-12">
        <div class="card py-3">
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="img-slider">
                <img
                  :src="product.images[0]"
                  alt="product image"
                  class="w-100 h-100"
                />
              </div>
            </div>
            <div class="col-12 col-sm-9">
              <div class="prod-details">
                <!-- <span class="badge bg-primary">Shipped from abroad</span> -->
                <h5 class="prod-name mt-2 mb-0">{{ product.name }}</h5>
                <div class="brand">
                  <div class="prod-b-bname d-flex align-items-center mt-2">
                    <span class="pbb-title text-muted f-13">Product code:</span>
                    <h6 class="ms-2 f-14 my-0">{{ product.data_id }}</h6>
                  </div>
                  <div class="prod-b-bname d-flex align-items-center mt-2">
                    <!-- <span class="pbb-title text-muted f-13">Brand:</span>
                    <h6 class="ms-2 f-14 my-0">BSMP brand</h6> -->
                  </div>
                </div>
                <div class="prod-rating d-flex align-items-center">
                  <div class="star">
                    <i
                      v-for="i in 5"
                      :key="i"
                      class="fa fa-star"
                      :class="
                        i <= Math.floor(product.rating[0])
                          ? 'rating-filled'
                          : ''
                      "
                    >
                    </i>
                  </div>
                  <p v-if="product.rating[0] == 0" class="mb-0">
                    (No ratings available)
                  </p>
                </div>
                <div class="prod-cost">
                  <h4 class="prod-price mt-2">
                    {{ product.price.replace(/\s/g, "") }}
                  </h4>
                  <p class="prod-shipping">
                    {{ product.full_delivery }}
                  </p>
                </div>
                <div class="prod-variation mt-2">
                  <div
                    class="add-to-cart"
                    v-if="product.quantity || product.quantity > 0"
                  >
                    <button
                      class="btn k-btn"
                      :disabled="product.quantity == 0"
                      @click="reduceOrRemoveFromCart()"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                    <div class="cart-input">{{ product.quantity }}</div>
                    <button class="btn k-btn" @click="addToCart()">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                  <div class="mt-2 pe-4" v-else>
                    <button class="btn add-cart py-2" @click="addToCart()">
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-sm-4 col-12">
        <div class="card d-returns">
          <div class="card-header bg-white py-2">
            <h6 class="card-title mb-0">DELIVERY & RETURNS</h6>
          </div>
          <div class="card-body py-2">
            <span class="text-muted">Choose your location</span>
            <form class="form mt-2">
              <div class="form-group">
                <multiselect
                  v-model="state"
                  :options="states"
                  label="state"
                  @select="getCities($event)"
                >
                </multiselect>
              </div>
              <div class="form-group">
                <multiselect
                  v-model="city"
                  :options="cities"
                  label="name"
                  @select="getDeliveryPrice($event)"
                >
                </multiselect>
              </div>
            </form>
            <div class="door-note d-flex">
              <div class="dn-icon">
                <i class="fas fa-truck"></i>
              </div>
              <div class="dn-content ms-1">
                <p class="mb-0">Door delivery</p>
                <span
                  >Delivery <b>₦{{ delivery.delivery_fee }}</b>
                </span>
                <p
                  class="dn-date text-muted"
                  v-html="delivery.delivery_date"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="card mt-3">
      <div class="card-header bg-white py-3">
        <h6 class="card-title mb-0">Product Details</h6>
      </div>
      <div class="card-body">
        <p v-html="product.details"></p>
      </div>
    </div>
  </div>
</template>

<script>
// import { Splide, SplideSlide } from "@splidejs/vue-splide";
// import "@splidejs/splide/dist/css/splide.min.css";
import { mapState } from "vuex";
// import axios from "axios";
// import Multiselect from "vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    // Splide,
    // SplideSlide,
    // Multiselect,
  },
  computed: {
    ...mapState({
      cart: (context) => context.cart["konga"],
    }),
  },
  data() {
    return {
      loaded: false,
      product: {},
      state: { id: 1061493610, state: "Lagos" },
      cities: [],
      city: "",
      delivery: {},
      states: [],
    };
  },
  methods: {
    getDeliveryPrice(event) {
      this.city = event;
      this.delivery = {
        delivery_fee: this.product.delivery_fee,
        // delivery_date: resp.data.shipping_date,
        delivery_location: this.city.name,
      };
      console.log(this.delivery);
    },
    async getProduct() {
      await this.$store
        .dispatch("get", `konga/one/${this.$route.params.link}`)
        .then((resp) => {
          this.product = resp.data;
          console.log(this.product);
          //replace the src="data:image" with alt=""
          this.product.details = this.product.details.replace(
            /src="data:image/gi,
            'alt="'
          );
          this.product.details = this.product.details.replace(
            /data-src/gi,
            "src"
          );
          this.product.quantity = 0;
          this.cart.forEach((element) => {
            if (element.data_id == this.product.data_id) {
              this.product.quantity = element.quantity;
              this.product.delivery_fee = element.delivery_fee;
              this.product.delivery_date = element.delivery_date;
            }
          });

          this.delivery = {
            delivery_fee: this.product.delivery_fee,
            delivery_date: this.product.delivery_date,
          };
          this.loaded = true;
        });
    },
    async getCities(event) {
      console.log(event);
      if (event) {
        this.state = event;
      }
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("get", `konga/getcities/${this.state.id}`)
        .then((resp) => {
          this.$store.commit("setLoader", false);
          this.cities = resp.data;
          console.log(resp);
        });
    },
    addToCart() {
      this.$store.commit("addToCart", {
        data: this.product,
        merchant: "konga",
      });
      this.product = this.cart.find(
        (prod) => prod.data_id == this.product.data_id
      );
      this.$forceUpdate();
    },
    reduceOrRemoveFromCart() {
      this.$store.commit("reduceOrRemoveFromCart", this.product);
      console.log(this.product);
      if (this.product.quantity == 1) {
        this.product.quantity = 0;
      } else {
        this.product = this.cart.find(
          (prod) => prod.data_id == this.product.data_id
        );
      }
      // console.log(this.product);

      // this.$forceUpdate();
    },
    getStates() {
      this.$store.dispatch("get", "konga/getstates").then((resp) => {
        this.states = resp.data;
        console.log(this.states);
      });
    },
  },
  async created() {
    await this.getProduct();
    await this.getStates();
    this.getCities();
  },
};
</script>

<style scoped lang="scss">
.dn-icon {
  border: 1px solid #ededed;
  font-size: 20px;
  height: fit-content;
  padding: 8px 10px;
  border-radius: 5px;
}
.dn-content {
  font-size: 13px;
  span {
    font-size: 13px;
  }
  .dn-date {
    font-size: 13px;
  }
}

.rating-filled {
  color: #ec037f !important;
}
.img-slider {
  height: 300px;
  border-bottom: 1px solid #ededed;
  img {
    object-fit: contain;
  }
}
.prod-details {
  .prod-name {
    font-weight: normal;
    font-size: 20px;
  }
  .prod-rating {
    border-bottom: 1px solid #ededed;
    padding-bottom: 7px;
    p {
      color: #c7c7cc;
      font-size: 13px;
      margin-left: 8px;
      margin-top: 1px;
    }
    .star {
      i {
        color: #c7c7cc;
        font-size: 12px;
      }
    }
  }
  .prod-cost {
    border-bottom: 1px solid #ededed;
    .prod-shipping {
      font-weight: normal;
      font-size: 12px;
      margin-bottom: 0;
      padding-bottom: 10px;
    }
  }
  .prod-variation {
    .btn-variation {
      border: 1px solid #ec037f;
      color: #ec037f;
      padding: 5px 12px;
      font-size: 13px;
      margin-right: 10px;
    }
    .add-cart {
      width: 100%;
      background-color: #ec037f;
      color: #fff;
    }
    .add-to-cart {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .cart-input {
        width: 70px;
        text-align: center;
        border: none;
      }
      .k-btn {
        background-color: #ec037f;
        padding: 2px 10px;
        border-radius: 3px;
        i {
          color: #fff;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
