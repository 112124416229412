<script>
  import SalesCard from "../widgets/SalesCard.vue";

  export default {
    name: "cabletv",
    components: { SalesCard },
    data() {
      return {};
    },
  };
</script>

<template>
  <div>
    <h6 class="pb-2 border-bottom border-theme mb-4">Cable TV</h6>

    <div class="p-2">
      <div class="row row-gap-3">
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="DSTV Subscription"
            :route-name="'purchase.cabletv-purchase'"
            :has-route-title="true"
            query="dstv"
            title="DSTV Subscription"
            image-url="/assets/img/requests/dstv.svg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="GOtv Payment"
            :has-route-title="true"
            :route-name="'purchase.cabletv-purchase'"
            query="gotv"
            title="GOtv Subscription"
            image-url="/assets/img/requests/gotv.svg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="StarTimes"
            :has-route-title="true"
            :route-name="'purchase.cabletv-purchase'"
            query="startimes"
            title="StarTimes Subscription"
            image-url="/assets/img/requests/startime.svg"
          />
        </div>
        <div class="col-6 col-xl-3">
          <sales-card
            image-text="Showmax"
            :has-route-title="true"
            :route-name="'purchase.cabletv-purchase'"
            query="showmax"
            title="Showmax Subscription"
            image-url="/assets/img/requests/showmax.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>
