<template>
    <section class="help">
        <div class="heading">
            <div class="help-title">
                <h1>Help</h1>
                <p>Help center provides details on how to navigate the platform</p>
            </div>
            <div class="help-notice">
                <p>NOTE: <span>Any account details or information displayed is not real, it only serves as an example</span></p>
            </div>
        </div>

        <!-- mobile view -->
        <div class="heading-mobile" v-show="showNavigation">
            <div class="help-title">
                <h1>Help</h1>
                <p>Help center provides details on how to navigate the platform</p>
            </div>
            <div class="help-notice">
                <p>NOTE: <span>Any account details or information displayed is not real, it only serves as an example</span></p>
            </div>
        </div>
        <aside class="help-mobile">
            <nav class="sub-sidebar-mobile" v-show="showNavigation">
                <div class="content-title ">
                    <h3 class="text-14-16 mb-0">Table of contents</h3>
                </div>
                <div class="single-tab-dropdown">
                    <a href="#paymentRequestCollapse"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        class="d-flex align-items-center justify-content-between single-tab-mobile"
                        :class="{ 'active': isPaymentRequestOpen }"
                        @click="togglePaymentRequestAccordion"
                    >
                        <span :class="{ 'text-14-16 color-black-text': isPaymentRequestOpen, 'text-14-16 color-gray': !isPaymentRequestOpen }">Payment Request</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M2.24271 5.53499C2.1554 5.62208 2.08614 5.72553 2.03887 5.83943C1.99161 5.95333 1.96729 6.07543 1.96729 6.19875C1.96729 6.32206 1.99161 6.44416 2.03887 6.55806C2.08614 6.67196 2.1554 6.77541 2.24271 6.86249L8.47521 13.095C8.5446 13.1645 8.62701 13.2197 8.71774 13.2573C8.80847 13.295 8.90573 13.3143 9.00396 13.3143C9.10219 13.3143 9.19945 13.295 9.29018 13.2573C9.38091 13.2197 9.46333 13.1645 9.53271 13.095L15.7652 6.86249C16.1327 6.49499 16.1327 5.90249 15.7652 5.53499C15.3977 5.16749 14.8052 5.16749 14.4377 5.53499L9.00021 10.965L3.56271 5.5275C3.20271 5.1675 2.60271 5.16749 2.24271 5.53499Z" fill="#858585"/>
                        </svg>
                    </a>
                    <div id="paymentRequestCollapse" class="collapse">
                        <ul class="sub-sidebar-list-mobile d-grid">
                            <li><button @click="openMobileTabContent('OnlinePayment')">Online Payment</button></li>
                            <li><button @click="openMobileTabContent('RecordOfflinePayment')">Record Offline Payment</button></li>
                        </ul>
                    </div>
                </div>
                <div class="single-tab-dropdown">
                    <a href="#purchasesCollapse"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        class="d-flex align-items-center justify-content-between single-tab-mobile"
                        :class="{ 'active': isPurchaseOpen }"
                        @click="togglePurchaseAccordion"
                    >
                        <span :class="{ 'text-14-16 color-black-text': isPurchaseOpen, 'text-14-16 color-gray': !isPurchaseOpen }">Purchases</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M2.24271 5.53499C2.1554 5.62208 2.08614 5.72553 2.03887 5.83943C1.99161 5.95333 1.96729 6.07543 1.96729 6.19875C1.96729 6.32206 1.99161 6.44416 2.03887 6.55806C2.08614 6.67196 2.1554 6.77541 2.24271 6.86249L8.47521 13.095C8.5446 13.1645 8.62701 13.2197 8.71774 13.2573C8.80847 13.295 8.90573 13.3143 9.00396 13.3143C9.10219 13.3143 9.19945 13.295 9.29018 13.2573C9.38091 13.2197 9.46333 13.1645 9.53271 13.095L15.7652 6.86249C16.1327 6.49499 16.1327 5.90249 15.7652 5.53499C15.3977 5.16749 14.8052 5.16749 14.4377 5.53499L9.00021 10.965L3.56271 5.5275C3.20271 5.1675 2.60271 5.16749 2.24271 5.53499Z" fill="#858585"/>
                        </svg>
                    </a>
                    <div id="purchasesCollapse" class="collapse">
                        <ul class="sub-sidebar-list-mobile d-grid">
                            <li><button @click="openMobileTabContent('ShopOnline')">Shop Online</button></li>
                            <li><button @click="openMobileTabContent('PurchaseAirtime')">Purchase Airtime</button></li>
                        </ul>
                    </div>
                </div>
                <div class="single-tab-dropdown">
                    <a href="#approveEndorseCollapse"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        class="d-flex align-items-center justify-content-between single-tab-mobile"
                        :class="{ 'active': isApprovalEndorserOpen }"
                        @click="toggleApprovalEndorserAccordion"
                    >
                        <span :class="{ 'text-14-16 color-black-text': isApprovalEndorserOpen, 'text-14-16 color-gray': !isApprovalEndorserOpen }">Approval & Endorsement</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M2.24271 5.53499C2.1554 5.62208 2.08614 5.72553 2.03887 5.83943C1.99161 5.95333 1.96729 6.07543 1.96729 6.19875C1.96729 6.32206 1.99161 6.44416 2.03887 6.55806C2.08614 6.67196 2.1554 6.77541 2.24271 6.86249L8.47521 13.095C8.5446 13.1645 8.62701 13.2197 8.71774 13.2573C8.80847 13.295 8.90573 13.3143 9.00396 13.3143C9.10219 13.3143 9.19945 13.295 9.29018 13.2573C9.38091 13.2197 9.46333 13.1645 9.53271 13.095L15.7652 6.86249C16.1327 6.49499 16.1327 5.90249 15.7652 5.53499C15.3977 5.16749 14.8052 5.16749 14.4377 5.53499L9.00021 10.965L3.56271 5.5275C3.20271 5.1675 2.60271 5.16749 2.24271 5.53499Z" fill="#858585"/>
                        </svg>
                    </a>
                    <div id="approveEndorseCollapse" class="collapse">
                        <ul class="sub-sidebar-list-mobile d-grid">
                            <li><button @click="openMobileTabContent('ApproveDeclineRequest')">Approve & Decline Request</button></li>
                            <li><button @click="openMobileTabContent('EndorseDeclineRequest')">Endorse & Decline Request</button></li>
                        </ul>
                    </div>
                </div>
                <div class="single-tab-dropdown">
                    <a href="#financeTabCollapse"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        class="d-flex align-items-center justify-content-between single-tab-mobile"
                        :class="{ 'active': isFinanceOpen }"
                        @click="toggleFinanceAccordion"
                    >
                        <span :class="{ 'text-14-16 color-black-text': isFinanceOpen, 'text-14-16 color-gray': !isFinanceOpen }">Finance</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M2.24271 5.53499C2.1554 5.62208 2.08614 5.72553 2.03887 5.83943C1.99161 5.95333 1.96729 6.07543 1.96729 6.19875C1.96729 6.32206 1.99161 6.44416 2.03887 6.55806C2.08614 6.67196 2.1554 6.77541 2.24271 6.86249L8.47521 13.095C8.5446 13.1645 8.62701 13.2197 8.71774 13.2573C8.80847 13.295 8.90573 13.3143 9.00396 13.3143C9.10219 13.3143 9.19945 13.295 9.29018 13.2573C9.38091 13.2197 9.46333 13.1645 9.53271 13.095L15.7652 6.86249C16.1327 6.49499 16.1327 5.90249 15.7652 5.53499C15.3977 5.16749 14.8052 5.16749 14.4377 5.53499L9.00021 10.965L3.56271 5.5275C3.20271 5.1675 2.60271 5.16749 2.24271 5.53499Z" fill="#858585"/>
                        </svg>
                    </a>
                    <div id="financeTabCollapse" class="collapse">
                        <ul class="sub-sidebar-list-mobile d-grid">
                            <li><button @click="openMobileTabContent('CreateExpenseCategory')">Create Expense Category</button></li>
                            <li><button @click="openMobileTabContent('AddPayee')">Add Payee</button></li>
                            <li><button @click="openMobileTabContent('FundWallet')">Fund Wallet</button></li>
                            <li><button @click="openMobileTabContent('DownloadReports')">Download Reports</button></li>
                        </ul>
                    </div>
                </div>
                <div class="single-tab-dropdown">
                   
                    <ul class="sub-sidebar-list-mobile d-grid">
                            <li class="no-list-style"><button @click="openMobileTabContent('ManageSubscription')">Manage Subscription</button></li>
                    </ul>
                   
                </div>
                <div class="single-tab-dropdown mb-2">
                    <a href="#settingsCollapse"
                        data-bs-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        class="d-flex align-items-center justify-content-between single-tab-mobile"
                        :class="{ 'active': isSettingsOpen }"
                        @click="toggleSettingsAccordion"
                    >
                        <span :class="{ 'text-14-16 color-black-text': isSettingsOpen, 'text-14-16 color-gray': !isSettingsOpen }">Settings</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path d="M2.24271 5.53499C2.1554 5.62208 2.08614 5.72553 2.03887 5.83943C1.99161 5.95333 1.96729 6.07543 1.96729 6.19875C1.96729 6.32206 1.99161 6.44416 2.03887 6.55806C2.08614 6.67196 2.1554 6.77541 2.24271 6.86249L8.47521 13.095C8.5446 13.1645 8.62701 13.2197 8.71774 13.2573C8.80847 13.295 8.90573 13.3143 9.00396 13.3143C9.10219 13.3143 9.19945 13.295 9.29018 13.2573C9.38091 13.2197 9.46333 13.1645 9.53271 13.095L15.7652 6.86249C16.1327 6.49499 16.1327 5.90249 15.7652 5.53499C15.3977 5.16749 14.8052 5.16749 14.4377 5.53499L9.00021 10.965L3.56271 5.5275C3.20271 5.1675 2.60271 5.16749 2.24271 5.53499Z" fill="#858585"/>
                        </svg>
                    </a>
                    <div id="settingsCollapse" class="collapse">
                        <ul class="sub-sidebar-list-mobile d-grid">
                            <li><button @click="openMobileTabContent('GeneralSettings')">General Settings</button></li>
                            <li><button @click="openMobileTabContent('ManageTeamMembers')">Manage Team Members & Permissions</button></li>
                            <li><button @click="openMobileTabContent('ApprovalCircles')" class="mb-2">Approval Circles</button></li>
                        </ul>
                    </div>
                </div>
            </nav>

            <div>
                 <button v-if="showBackButton" @click="goBack" class="d-flex align-items-center gap-1 text-14-16 color-black-text back-btn">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M8.57812 14.0625L3.51562 9L8.57812 3.9375M4.21875 9H14.4844" stroke="#022438" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Back</span>
                </button>
            </div>
            <div class="main-content" v-if="showMainContent" >    
                 <component :is="helpTabs[activeTabs].component" />
            </div>
        </aside>

        <!-- desktop view -->
        <aside class="help-wrapper">
            <nav class="sub-sidebar">
                <ul class="sub-sidebar-list d-grid">
                    <li :class="{ 'active' : activeTabs == index }" :key="item.id" v-for="(item, index) in helpTabs">
                        <button @click="activeTabs = index">{{ item.name }}</button>
                    </li>
                </ul>
            </nav>
            <div class="main-content" >
                 <component :is="helpTabs[activeTabs].component" />
               
                <!-- <OnlinePayment v-if="activeTabs == 0" />
                <RecordOfflinePayment v-if="activeTabs == 1" />
                <ShopOnline v-if="activeTabs == 2" />
                <PurchaseAirtime v-if="activeTabs == 3" />
                <ApproveDeclineRequest v-if="activeTabs == 4" />
                <EndorseDeclineRequest v-if="activeTabs == 5" />
                <CreateExpenseCategory v-if="activeTabs == 6" />
                <AddPayee v-if="activeTabs == 7" />
                <FundWallet v-if="activeTabs == 8" />
                <DownloadReports v-if="activeTabs == 9" />
                <ManageSubscription v-if="activeTabs == 10" />
                <GeneralSettings v-if="activeTabs == 11" />
                <ManageTeamMembers v-if="activeTabs == 12" />
                <ApprovalCircles v-if="activeTabs == 13" />
                -->
            </div>
        </aside>
    </section>
</template>

<script>
import OnlinePayment from "../../../components/dashboard/help/onlinePayment.vue"
import RecordOfflinePayment from "../../../components/dashboard/help/recordOfflinePayment.vue"
import ShopOnline from "../../../components/dashboard/help/shopOnline.vue"
import PurchaseAirtime from "../../../components/dashboard/help/purchaseAirtime.vue"
import ApproveDeclineRequest from "../../../components/dashboard/help/approveDeclineRequest.vue"
import EndorseDeclineRequest from "../../../components/dashboard/help/endorseDeclineRequest.vue"
import CreateExpenseCategory from "../../../components/dashboard/help/createExpenseCategory.vue"
import AddPayee from "../../../components/dashboard/help/addPayee.vue"
import FundWallet from "../../../components/dashboard/help/fundWallet.vue"
import DownloadReports from "../../../components/dashboard/help/downloadReport.vue"
import ManageSubscription from "../../../components/dashboard/help/manageSubscriptions.vue"
import GeneralSettings from "../../../components/dashboard/help/generalSettings.vue"
import ManageTeamMembers from "../../../components/dashboard/help/manageTeamMembers.vue"
import ApprovalCircles from "../../../components/dashboard/help/approvalCircle.vue"
export default {
  components: { OnlinePayment, RecordOfflinePayment, ShopOnline, PurchaseAirtime, ApproveDeclineRequest, EndorseDeclineRequest, CreateExpenseCategory, AddPayee, FundWallet, DownloadReports, ManageSubscription, GeneralSettings, ManageTeamMembers, ApprovalCircles},
  data() {
    return {
      helpTabs: [
        {id: 1, name:"Online Payment", component: "OnlinePayment"},
        {id: 2, name:"Record Offline Payment", component: "RecordOfflinePayment" },
        {id: 3, name:"Shop Online", component: "ShopOnline"},
        {id: 4, name:"Purchase Airtime", component: "PurchaseAirtime"},
        {id: 5, name:"Approve & Decline Request", component: "ApproveDeclineRequest"},
        {id: 6, name:"Endorse & Decline Request", component: "EndorseDeclineRequest"},
        {id: 7, name:"Create Expense Category", component: "CreateExpenseCategory"},
        {id: 8, name:"Add Payee", component: "AddPayee"},
        {id: 9, name:"Fund Wallet", component: "FundWallet"},
        {id: 10, name:"Download Reports", component: "DownloadReports"},
        {id: 11, name:"Manage Subscription", component: "ManageSubscription"},
        {id: 12, name:"General Settings", component: "GeneralSettings"},        
        {id: 13, name:"Manage Team Members & Permissions", component: "ManageTeamMembers"},
        {id: 14, name:"Approval Circles", component: "ApprovalCircles"},
      ],
      activeTabs: 0,
      showNavigation: true,
      showBackButton: false,
      showMainContent: false,

    //   accordions
      isPaymentRequestOpen: false,
      isPurchaseOpen: false,
      isApprovalEndorserOpen: false,
      isFinanceOpen: false,
      isSettingsOpen: false,
    };
  },
  methods: {
    handleMobileItemClick(index) {
      this.activeTabs = index;
      this.showNavigation = false;
    },

    openMobileTabContent(componentName) {
      // Find the index of the component in helpTabs array
      const tabIndex = this.helpTabs.findIndex(tab => tab.component === componentName);

      // Set the activeTabs to the found index
      this.activeTabs = tabIndex;

      // Set showNavigation to false to hide the navigation bar
      this.showNavigation = false;
      this.showMainContent = true;

      this.showBackButton = true;
      
    },

    goBack() {
      // Set showNavigation to true to show the navigation bar
      this.showNavigation = true;

      // Set showBackButton to false to hide the "Back" button
      this.showBackButton = false;
      this.showMainContent = false;
    },

    togglePaymentRequestAccordion() {
            this.isPaymentRequestOpen = !this.isPaymentRequestOpen;
        },
    togglePurchaseAccordion() {
        this.isPurchaseOpen = !this.isPurchaseOpen;
    },
    toggleApprovalEndorserAccordion() {
        this.isApprovalEndorserOpen = !this.isApprovalEndorserOpen;
    },
    toggleFinanceAccordion() {
        this.isFinanceOpen = !this.isFinanceOpen;
    },
    toggleSettingsAccordion() {
        this.isSettingsOpen = !this.isSettingsOpen;
    },
   
  },
}
</script>

<style lang="scss" scoped>

    .color-gray {
        color:  #848484 !important;
    }
    .color-black-text {
        color:  #022438 !important;
    }
    img {
        max-width: 100%;
    }
    .help{
        padding: 10px 0px;
    }
    .heading {
        display: none;
    }
    .heading-mobile {
        display: block;
    }
    .help-title{
        font-weight: 400;
        h1{
            color:  #022438;
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 0.5rem;
        }
        p{
            color:  #848484;
            font-size: 14px;
            line-height: 24px; 
        }
    }
    .help-notice{
        margin-bottom: 1.5rem;
        p{
            color: #022438;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
        }
        span{
            color: #848484;
            font-weight: 400;
        }
    }
    .help-wrapper{
        display: none;
    }
    .sub-sidebar-mobile {
        background: #FDFDFF;
        border: 1px solid #DFDFDF;
        border-radius: 16px;
        width: 100%;
        transition: transform 0.3s ease-in-out;
    }
    .hidden {
    transform: translateX(-100%);
    }
    .sub-sidebar {
        background: #FDFDFF;
        padding-top: 20px;
        border: 1px solid #DFDFDF;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 260px;
    }
    .sub-sidebar-list{
        gap: 10px;
        padding-left: 0;
        width: 260px;

        li {
            display: block;
            list-style: none;
            padding: 10px 20px;

            a, button{
                display: block;
                width: 100%;
                color: #022438;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                text-align: left;
                border: none;
                background: inherit;
                padding: 0;
            }
        }
        li.active{
            border: 1px solid #DFDFDF;
            border-left: none;
            background: #F4F5F7;
            width: 99.5%;
        }
        
    }
    .sub-sidebar-list-mobile {
        padding-left: 0;
        list-style-type: disc !important;
        margin-bottom: 0 !important;
        /* list-style: inside; */
        li {
            display: block;
            padding: 8px 20px 8px 0;
            display: list-item;
            margin-left: 35px;

            a, button{
                display: block;
                width: 100%;
                color: #848484;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                text-align: left;
                border: none;
                background: inherit;
                padding: 0;
            }
        }
        li.active{
            border: 1px solid #DFDFDF;
            border-left: none;
            background: #F4F5F7;
            width: 99.5%;
        }
    }
    .no-list-style {
        display: block !important;
        margin-left: 20px !important;
    }
    .single-tab-dropdown:not(:last-child){
        border-bottom: 1px solid #DFDFDF;
        padding: 6px 0;
    }

    .single-tab-mobile {
        width: 100%;
        padding: 10px 20px;
    }
    .main-content {
        padding: 23px 20px 37px 20px;
        background: #fff;
        border: 1px solid #DFDFDF;
        border-radius: 8px;
    }
    .sub-title{
        gap: 0.5rem;
        margin-bottom: 11px;
        border-bottom: 1px solid #DFDFDF;
        padding-bottom: 10px;
        h2{
            color: #022438;
            font-size: 16px;
            font-weight: 400;
            line-height: 18px;
        }
    }
    .back-btn {
        border: none;
        background: none;
        margin-bottom: 13px;
    }
    .text-14-24{
        color: #848484;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
    .text-14-16{
        color:  #022438;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
    .content-title {
        padding: 16px 20px;
        border-bottom: 1px solid #DFDFDF;
        border-radius: 16px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        background: #FAFAFF;
    }
    @media (min-width: 780px){
        .help{
        padding: 14px 24px;
        /* margin-bottom: 1.5rem; */
        }
        .heading {
            display: block;
        }
        .heading-mobile {
            display: none;
        }
        .help-title{
            h1{
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 0.5rem;
            }
            p{
                font-size: 14px;
                line-height: 22px; 
            }
        }
        .help-wrapper{
            display: flex;
        }
        .sub-sidebar-mobile {
        display: none;
    }
    .help-mobile {
        display: none;
    }
    .main-content {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    }
</style>