var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border border-theme rounded-t-4 rounded-4"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center py-2"},[_c('div',{staticStyle:{"width":"120px","height":"80px","position":"relative"}},[_c('img',{staticClass:"w-100 h-100 object-cover rounded-4 mix-blend-mode-darken",class:{ 'object-contain': _vm.contain },attrs:{"src":_vm.imageUrl,"alt":"sales-image.png"}})])]),_c('router-link',{staticClass:"btn btn-primary text-capitalize w-full border border-theme rounded-4 fs-7",attrs:{"to":{
      name: _vm.routeName,
      query: {
        type: _vm.query ? _vm.query : _vm.imageText.replace(/ /g, '').toLocaleUpperCase(),
        title: _vm.hasRouteTitle ? _vm.title : '',
      },
    }}},[_vm._v(" "+_vm._s(_vm.imageText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }