<template>
  <div class="jumia px-2 py-4">
    <konga-navbar></konga-navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import KongaNavbar from "@/components/dashboard/konga/navbar";
export default {
  components: { KongaNavbar },
};
</script>

<style></style>
