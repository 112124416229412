import Konga from "../views/dashboard/konga/index.vue";
import KongaProductView from "../views/dashboard/konga/view.vue";
import KongaCart from "../views/dashboard/konga/cart.vue";
import KongaCheckout from "../views/dashboard/konga/checkout.vue";

const KongaRoutes = [
  {
    path: "/konga",
    name: "konga",
    component: Konga,
  },
  {
    path: "/konga/product/:link",
    name: "konga-product-view",
    component: KongaProductView,
  },
  {
    path: "/konga/cart",
    name: "konga-cart",
    component: KongaCart,
  },
  {
    path: "/konga/checkout",
    name: "konga-checkout",
    component: KongaCheckout,
  },
];
export default KongaRoutes;
