<template>
  <div class="orders px-2 py-4">
    <div class="d-flex justify-content-between align-items-center">
      <h4>Waitlist</h4>
    </div>
    <div class="orders-body mt-5">
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <td>S/N</td>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(email, index) in emails" :key="index">
                  <td>{{ ++index }}</td>
                  <td>{{ email.email }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      emails: [],
    };
  },
  methods: {
    getWaitlist() {
      this.$store.dispatch("get", "waitlist").then((resp) => {
        console.log(resp.data);
        this.emails = resp.data;
      });
    },
  },
  created() {
    this.getWaitlist();
  },
};
</script>

<style scoped lang="scss">
.merchants-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .m-logo {
    // border: 1px solid gray;
    margin-right: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 10px;
    width: 150px;
    background-color: transparent;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 15px;
    span {
      font-size: 13px;
    }
  }
}
</style>
