<template>
  <div class="cart">
    <div class="row" v-if="cart.length > 0">
      <div class="col-12 col-md-9">
        <div class="card">
          <div class="card-header bg-white">
            <h6 class="card-title mb-0">Cart ({{ cart.length }})</h6>
          </div>
          <div class="card-body pt-0">
            <div class="cart-items">
              <div
                class="cart-item"
                v-for="(item, key) in this.cart"
                :key="key"
              >
                <div class="ci-top">
                  <div class="ci-left">
                    <div class="ci-img">
                      <img
                        :src="item.image"
                        alt="product image"
                        class="w-100 h-100"
                      />
                    </div>
                    <div class="ci-content">
                      <h6 class="ci-prod-name mb-0">
                        {{ item.name }}
                      </h6>
                    </div>
                  </div>
                  <div class="ci-right">
                    <h5 class="ci-price">
                      ₦{{
                        formatPrice(replaceNaira(item.price) * item.quantity)
                      }}
                    </h5>
                  </div>
                </div>
                <div class="ci-bottom">
                  <div class="ci-remove">
                    <button
                      class="btn btn-remove"
                      @click="removeFromCart(item)"
                    >
                      <i class="fa fa-trash"></i>
                      REMOVE
                    </button>
                  </div>
                  <div class="add-to-cart">
                    <button
                      class="btn k-btn"
                      :disabled="item.quantity == 1"
                      @click="reduceOrRemoveFromCart(item)"
                    >
                      <i class="fas fa-minus"></i>
                    </button>
                    <div class="cart-input">{{ item.quantity }}</div>
                    <button class="btn k-btn" @click="addQuantity(item)">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="card">
          <div class="card-header bg-white">
            <h6 class="card-title mb-0">CART SUMMARY</h6>
          </div>
          <div class="card-body pb-2">
            <div
              class="subtotal d-flex justify-content-between align-items-center mb-2"
            >
              <p class="mb-0">Subtotal</p>
              <h5 class="mb-0">₦{{ formatPrice(calculateSubTotal) }}</h5>
            </div>

            <span class="sub-note">Delivery fees not included yet.</span>
          </div>
          <div class="card-footer bg-white">
            <router-link to="/xerox/checkout" class="btn k-btn w-100"
              >CHECKOUT</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="alert alert-info text-center">
      <p>Cart is empty</p>
      <router-link to="/xerox" class="btn k-btn">Start Shopping</router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      cart: (context) => context.cart["xerox"],
    }),
    calculateSubTotal: function () {
      var vm = this;
      const total = this.cart.reduce(function (start, item) {
        return start + vm.replaceNaira(item.price) * item.quantity;
      }, 0);
      return total;
    },
    calculateDelivery: function () {
      var vm = this;
      const total = this.cart.reduce(function (start, item) {
        return start + vm.parsePrice(item.delivery_fee);
      }, 0);
      return total;
    },
  },
  methods: {
    addQuantity(product) {
      product.update = false;
      this.$store.commit("addToCart", { data: product, merchant: "xerox" });
    },
    reduceOrRemoveFromCart(product) {
      this.$store.commit("reduceOrRemoveFromCart", {
        data: product,
        merchant: "xerox",
      });
    },
    removeFromCart(product) {
      this.$store.commit("removeFromCart", {
        data: product,
        merchant: "xerox",
      });
    },
    replaceNaira(price) {
      // var hold = price.replace("₦", "");
      // hold = hold.replace(",", "");
      return this.parsePrice(price);
    },
  },
  created() {
    console.log(this.cart);
  },
};
</script>

<style scoped lang="scss">
.cart {
  .subtotal {
    flex-wrap: wrap;
    p {
      font-size: 14px;
    }
    h5 {
      font-size: 18px;
    }
  }
  .sub-note {
    font-size: 12px;
    color: #75757a;
  }
  .cart-items {
    .ci-price {
      font-size: 20px;
    }
    .cart-item {
      border-bottom: 1px solid #ededed;
      padding-bottom: 10px;
      margin-top: 20px;
      .ci-top {
        display: flex;
        justify-content: space-between;
        // padding: 10px 15px;
        .ci-left {
          display: flex;
          .ci-img {
            width: 6em;
            height: 5em;
            img {
              object-fit: contain;
            }
          }
          .ci-content {
            margin-left: 7px;
            .ci-prod-name {
              font-weight: normal;
              font-size: 14px;
            }
            span {
              font-size: 14px;
              font-weight: 300;
            }
          }
        }
      }
      .ci-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        align-items: center;
        .btn-remove {
          color: #ec037f;
          margin-left: -8px;
          border-radius: 3px;
          font-size: 13px;
          i {
            color: #ec037f;
            margin-right: 15px;
          }
          &:hover {
            background-color: #ebb5d2;
          }
        }
      }
    }
  }
  .card-footer a {
    font-size: 13px;
  }
}
@media (max-width: 768px) {
  .cart .cart-items .cart-item .ci-top {
    flex-wrap: wrap;
  }
  .card {
    margin-top: 10px;
  }
}
</style>
