<template>
  <div>
    <div class="pending d-flex">
      <div class="card">
        <div class="card-body">
          <div class="icon py-4">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <p class="text-center p-color mb-0">Waiting for admin approval.</p>
          <p class="text-center p-color mt-0">
            Please contact the administrator
          </p>
          <div class="text-center">
            <router-link class="btn btn-primary px-5" :to="{ name: 'login' }"
              >Login</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.btn-primary {
  background: #0a0a0a;
  border: none;
}
.pending {
  min-width: 100vw;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  .card {
    width: 35em;
    padding: 30px;
  }
  .p-color {
    color: black;
  }
  .icon {
    text-align: center;
    i {
      font-size: 60px;
      color: #24b3d2;
    }
  }
}
</style>
