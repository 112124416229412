<template>
  <p v-if="isOffline" class="p-0 alert alert-danger text-center offline">
    No internet connection
  </p>
</template>

<script>
export default {};
</script>

<style scoped>
.offline {
  z-index: 999999;
  position: fixed;
  width: 100%;
}
</style>
