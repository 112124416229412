<template>
  <section class="help">
    <div class="help-title">
      <h1>Help Desk</h1>
      <p class="mb-0">You can reach out to us via the means below</p>
    </div>
    <div class="contact-box">
      <div class="small-box-2">
        <div class="d-flex gap-2 align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M5 5H18C18.7957 5 19.5587 5.31607 20.1213 5.87868C20.6839 6.44129 21 7.20435 21 8V17C21 17.7957 20.6839 18.5587 20.1213 19.1213C19.5587 19.6839 18.7957 20 18 20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7957 2 17V8C2 7.20435 2.31607 6.44129 2.87868 5.87868C3.44129 5.31607 4.20435 5 5 5ZM5 6C4.5 6 4.06 6.17 3.72 6.47L11.5 11.5L19.28 6.47C18.94 6.17 18.5 6 18 6H5ZM11.5 12.71L3.13 7.28C3.05 7.5 3 7.75 3 8V17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H18C18.5304 19 19.0391 18.7893 19.4142 18.4142C19.7893 18.0391 20 17.5304 20 17V8C20 7.75 19.95 7.5 19.87 7.28L11.5 12.71Z"
              fill="#022438"
            />
          </svg>
          <p class="mb-0 desc">Via Email</p>
        </div>
        <form class="d-grid gap-3 form-box">
          <div class="form-group">
            <label for="" class="desc-3"
              >Subject<span class="required-field">*</span></label
            >
            <select class="form-control f-14" v-model="subject">
              <option disabled selected>Subject</option>
              <option value="bug">Bug</option>
              <option value="Feature addition">Feature addition</option>
              <option value="Delete my account">Delete my account</option>
              <option value="others">Others</option>
            </select>
          </div>
          <div class="" v-if="subject == 'others'">
            <label for="custom-subject" class="d-block desc-3"
              >Custom Subject<span class="required-field">*</span></label
            >
            <input
              type="text"
              v-model="customSubject"
              name="custom-subject"
              id="custom-subject"
              placeholder="Type in custom email subject"
              class="form-control f-14"
            />
          </div>
          <div class="">
            <label for="" class="d-block desc-3"
              >Message<span class="required-field">*</span></label
            >
            <textarea
              name=""
              id=""
              cols="30"
              rows="4"
              placeholder="Enter your message"
              class="form-control f-14"
              v-model="message"
            ></textarea>
          </div>
        </form>

        <div class="d-flex btn-box">
          <button class="btn btn-primary f-14 button-submit" @click.prevent="submitForm()">
            Submit
          </button>
        </div>
      </div>
      <div class="small-box">
        <div class="d-flex gap-2 align-items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.515483 11.8563C0.514921 13.8728 1.04579 15.8417 2.05522 17.5771L0.418945 23.5066L6.53289 21.9156C8.22393 22.8292 10.1186 23.308 12.044 23.3081H12.0491C18.4051 23.3081 23.5791 18.1748 23.5818 11.8653C23.583 8.80792 22.3844 5.93295 20.2068 3.76997C18.0295 1.60718 15.1339 0.415458 12.0486 0.414062C5.69179 0.414062 0.518201 5.54709 0.515577 11.8563"
              fill="url(#paint0_linear_2850_151888)"
            />
            <path
              d="M0.103217 11.8527C0.102561 13.9417 0.652452 15.981 1.69787 17.7786L0.00292969 23.9207L6.3361 22.2726C8.0811 23.2168 10.0458 23.7147 12.045 23.7154H12.0501C18.6342 23.7154 23.994 18.3975 23.9968 11.8621C23.998 8.69488 22.7563 5.71656 20.5009 3.47609C18.2451 1.23591 15.2459 0.00130233 12.0501 0C5.46492 0 0.105841 5.31721 0.103217 11.8527ZM3.87476 17.469L3.63829 17.0965C2.64423 15.5277 2.11955 13.7149 2.1203 11.8534C2.12245 6.4213 6.5767 2.00186 12.0539 2.00186C14.7063 2.00298 17.1991 3.02921 19.074 4.89116C20.9488 6.7533 21.9804 9.22865 21.9798 11.8614C21.9773 17.2935 17.523 21.7135 12.0501 21.7135H12.0462C10.2642 21.7126 8.51646 21.2376 6.99228 20.34L6.62956 20.1265L2.87133 21.1045L3.87476 17.4689V17.469Z"
              fill="url(#paint1_linear_2850_151888)"
            />
            <path
              d="M9.06402 6.89746C8.84039 6.40416 8.60504 6.39421 8.39238 6.38556C8.21824 6.37811 8.01916 6.37867 7.82027 6.37867C7.6212 6.37867 7.29775 6.453 7.02435 6.74928C6.75067 7.04583 5.97949 7.76249 5.97949 9.22007C5.97949 10.6777 7.04919 12.0864 7.19831 12.2843C7.34761 12.4817 9.26338 15.5686 12.2975 16.7562C14.8191 17.743 15.3322 17.5468 15.8795 17.4973C16.4269 17.448 17.6457 16.7808 17.8943 16.089C18.1432 15.3973 18.1432 14.8043 18.0686 14.6804C17.994 14.557 17.7949 14.4829 17.4964 14.3348C17.1978 14.1866 15.7302 13.4698 15.4566 13.371C15.1829 13.2722 14.984 13.2229 14.7849 13.5195C14.5858 13.8157 14.0142 14.4829 13.8399 14.6804C13.6659 14.8785 13.4916 14.9031 13.1932 14.7549C12.8945 14.6063 11.9332 14.2938 10.7926 13.2846C9.90521 12.4993 9.30612 11.5296 9.13197 11.2329C8.95783 10.9367 9.11332 10.7762 9.263 10.6285C9.39712 10.4958 9.56161 10.2826 9.71101 10.1096C9.85985 9.93663 9.90953 9.81318 10.0091 9.6156C10.1087 9.41783 10.0588 9.24481 9.98432 9.09663C9.90953 8.94844 9.32945 7.48323 9.06402 6.89746Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2850_151888"
                x1="1158.56"
                y1="2309.67"
                x2="1158.56"
                y2="0.414062"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#1FAF38" />
                <stop offset="1" stop-color="#60D669" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_2850_151888"
                x1="1199.7"
                y1="2392.07"
                x2="1199.7"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#F9F9F9" />
                <stop offset="1" stop-color="white" />
              </linearGradient>
            </defs>
          </svg>
          <p class="mb-0 desc">Via WhatsApp</p>
        </div>
        <div class="small-box-inner">
          <div>
            <p class="desc-2 mb-0">
              Contact with our team via WhatsApp and start a conversation with
              them about your inquiry
            </p>
          </div>
          <a
            href="https://api.whatsapp.com/send/?phone=2348184146387&text&type=phone_number"
            target="_blank"
            class="button-group d-flex"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.515483 11.8563C0.514921 13.8728 1.04579 15.8417 2.05522 17.5771L0.418945 23.5066L6.53289 21.9156C8.22393 22.8292 10.1186 23.308 12.044 23.3081H12.0491C18.4051 23.3081 23.5791 18.1748 23.5818 11.8653C23.583 8.80792 22.3844 5.93295 20.2068 3.76997C18.0295 1.60718 15.1339 0.415458 12.0486 0.414062C5.69179 0.414062 0.518201 5.54709 0.515577 11.8563"
                fill="url(#paint0_linear_2850_151888)"
              />
              <path
                d="M0.103217 11.8527C0.102561 13.9417 0.652452 15.981 1.69787 17.7786L0.00292969 23.9207L6.3361 22.2726C8.0811 23.2168 10.0458 23.7147 12.045 23.7154H12.0501C18.6342 23.7154 23.994 18.3975 23.9968 11.8621C23.998 8.69488 22.7563 5.71656 20.5009 3.47609C18.2451 1.23591 15.2459 0.00130233 12.0501 0C5.46492 0 0.105841 5.31721 0.103217 11.8527ZM3.87476 17.469L3.63829 17.0965C2.64423 15.5277 2.11955 13.7149 2.1203 11.8534C2.12245 6.4213 6.5767 2.00186 12.0539 2.00186C14.7063 2.00298 17.1991 3.02921 19.074 4.89116C20.9488 6.7533 21.9804 9.22865 21.9798 11.8614C21.9773 17.2935 17.523 21.7135 12.0501 21.7135H12.0462C10.2642 21.7126 8.51646 21.2376 6.99228 20.34L6.62956 20.1265L2.87133 21.1045L3.87476 17.4689V17.469Z"
                fill="url(#paint1_linear_2850_151888)"
              />
              <path
                d="M9.06402 6.89746C8.84039 6.40416 8.60504 6.39421 8.39238 6.38556C8.21824 6.37811 8.01916 6.37867 7.82027 6.37867C7.6212 6.37867 7.29775 6.453 7.02435 6.74928C6.75067 7.04583 5.97949 7.76249 5.97949 9.22007C5.97949 10.6777 7.04919 12.0864 7.19831 12.2843C7.34761 12.4817 9.26338 15.5686 12.2975 16.7562C14.8191 17.743 15.3322 17.5468 15.8795 17.4973C16.4269 17.448 17.6457 16.7808 17.8943 16.089C18.1432 15.3973 18.1432 14.8043 18.0686 14.6804C17.994 14.557 17.7949 14.4829 17.4964 14.3348C17.1978 14.1866 15.7302 13.4698 15.4566 13.371C15.1829 13.2722 14.984 13.2229 14.7849 13.5195C14.5858 13.8157 14.0142 14.4829 13.8399 14.6804C13.6659 14.8785 13.4916 14.9031 13.1932 14.7549C12.8945 14.6063 11.9332 14.2938 10.7926 13.2846C9.90521 12.4993 9.30612 11.5296 9.13197 11.2329C8.95783 10.9367 9.11332 10.7762 9.263 10.6285C9.39712 10.4958 9.56161 10.2826 9.71101 10.1096C9.85985 9.93663 9.90953 9.81318 10.0091 9.6156C10.1087 9.41783 10.0588 9.24481 9.98432 9.09663C9.90953 8.94844 9.32945 7.48323 9.06402 6.89746Z"
                fill="white"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2850_151888"
                  x1="1158.56"
                  y1="2309.67"
                  x2="1158.56"
                  y2="0.414062"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#1FAF38" />
                  <stop offset="1" stop-color="#60D669" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_2850_151888"
                  x1="1199.7"
                  y1="2392.07"
                  x2="1199.7"
                  y2="0"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F9F9F9" />
                  <stop offset="1" stop-color="white" />
                </linearGradient>
              </defs>
            </svg>
            <p class="desc text-12 mb-0">WhatsApp</p>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        customSubject: "",
        subject: "",
        message: "",
        
      };
    },
    methods: {
      submitForm() {
        
        if (this.subject.length < 1) {
          window.ToasterAlert("error", "Subject is required");
          return false;
        }
        if (this.subject == "others" && this.customSubject.length < 1) {
          window.ToasterAlert("error", "Custom subject is required");
          return false;
        }
        if(this.subject == "others"){
          this.subject = this.customSubject
        }
        this.$store.commit('setLoader', true)
        this.$store
          .dispatch("post", {
            endpoint: "contactus", 
            details: {
              email: this.$store.state.user.email,
              company_name: this.$store.state.user.company,
              subject:this.subject,
              content: this.message
            },
          })
          .then((resp) => {
            console.log(resp);
            this.$store.commit('setLoader', true)
            window.ToasterAlert("success", "Message submitted");

            window.setTimeout(function () {
              window.location.reload();
            }, 2000);
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .help {
    padding: 10px 0px;
  }
  .help-title {
    font-weight: 400;
    margin-bottom: 20px;
    h1 {
      color: #022438;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 0.5rem;
    }
    p {
      color: #848484;
      font-size: 14px;
      line-height: 22px;
    }
  }
  .contact-box {
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
  }
  .small-box {
    display: flex;
    /* max-width: 320px; */
    width: 100%;
    padding: 12px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    background: #ffffff;
  }
  .small-box-inner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    background: #fdfdff;
    padding: 12px;
  }

  .small-box-2 {
    display: flex;
    width: 100%;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    background: #ffffff;
  }

  .desc {
    color: #022438;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }

  .desc-2 {
    color: #848484;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
  }
  .desc-3 {
    display: block !important;
    color: #848484 !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 6px !important;
  }
  .text-12 {
    font-size: 12px;
    line-height: 120%;
  }
  .button-group {
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    gap: 7px;
    border-radius: 6px;
    border: 1px solid #022438;
    background: #fff;
  }
  .button-submit {
    padding: 7px 25px;
    font-size: 13px;
    border-radius: 5px;
  }
  .form-box {
    width: 100%;
  }
  .form-group {
    margin-bottom: 0 !important;
  }
  .required-field {
    color: #dc3646;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    padding-left: 4px;
  }
  .btn-box {
    width: 100%;
  }

  @media (min-width: 775px) {
    .help {
      padding: 14px 24px;
      /* margin-bottom: 1.5rem; */
    }
    .contact {
      gap: 38px;
    }
    .help-title {
      h1 {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .contact-box {
      /* max-width: 1182px; */
      width: fit-content;
      flex-wrap: nowrap;
    }
    .small-box {
      padding: 14px;
      flex-basis: 42%;
    }
    .small-box-2 {
      padding: 14px;
      flex-basis: 58%;
    }
  }

  @media (min-width: 1000px) {
    .contact-box {
      /* grid-template-columns: 1fr 1fr; */
      width: 100%;
      max-width: 1182px;
      flex-wrap: nowrap;
    }
    .small-box {
      align-self: flex-start;
    }
    .btn-box {
      justify-content: flex-end;
      width: 100%;
    }
  }
  @media (min-width: 1300px) {
    .contact-box {
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
  }
</style>
