<script>
export default {
  name: "InvoicePayments",

  data() {
    return {};
  },
};
</script>

<template>
  <div>
    <h6 class="pb-2 border-bottom border-theme mb-4">Invoice Payments</h6>
  </div>
</template>
