export default {
  methods: {
    parsePermissions(value) {
      return value.split(",");
    },
    checkPermissions(value, roles) {
      return roles.some((item) => item == value);
    },
    checkValidDate(value) {
      return new Date(value).getTime() === new Date(value).getTime();
    },
    parsePrice(value, point = 2) {
      var hold = 0;
      if (value) {
        hold = value.toString();
        hold = hold.replace("₦", "");
        hold = hold.replace(",", "");
      }
      // console.log(parseFloat(hold).toFixed(2));
      return parseFloat(hold).toFixed(point);
    },
    restructureDate(value) {
      if (this.checkValidDate(value)) {
        var month = Number(new Date(value).getMonth()) + 1;
        month = month < 10 ? `0${month}` : month;
        var day = new Date(value).getDate();
        day = day < 10 ? `0${day}` : day;
        var hold = new Date(value).getFullYear() + "-" + month + "-" + day;

        return hold;
      } else {
        return "Invalid date";
      }
    },
    formatPrice(value) {
      return value ? new Intl.NumberFormat().format(value) : 0;
    },
    formatDateTime(value) {
      if (this.checkValidDate(value)) {
        var hold = new Date(value).toDateString().split(" ");
        return `${hold[2]} ${hold[1]} '${hold[3][2]}${hold[3][3]}`;
      } else {
        return "Invalid date";
      }
    },
    formatDateToLocalDate(value) {
      if (this.checkValidDate(value)) {
        return new Date(value).toLocaleDateString();
      } else {
        return "Invalid date";
      }
    },
    formatDateTimeSecond(value) {
      if (this.checkValidDate(value)) {
        let m = new Date(value);
        return m.toUTCString().replace("GMT", "");
      } else {
        return "Invalid date";
      }
    },
    roundUpAmount(value, fixed = 2) {
      return value ? parseFloat(value.toString()).toFixed(fixed) : 0;
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    redirectToMerchantsPage(merchant) {
      var formdata = new FormData();
      if (merchant == "amazon") {
        this.$store.commit("setLoader", true);
        formdata.append("email", this.$store.state.user.email);
        this.$store
          .dispatch("post", {
            endpoint: "punchout/amazon/start/" + this.$store.state.user.email,
          })
          .then((resp) => {
            this.$store.commit("setLoader", false);
            window.open(resp.data);
          })
          .catch((err) => {
            this.$store.commit("setLoader", false);
            this.$store.dispatch("handleError", err);
          });
      } else if (merchant == "jumia") {
        window.location = "jumia";
      } else if (merchant == "konga") {
        window.location = "konga";
      } else if (merchant == "xerox") {
        window.location = "xerox";
      }
    },
    // debounce(fn, wait) {
    //   console.log("kk");
    //   let timer;
    //   return function (...args) {
    //     if (timer) {
    //       clearTimeout(timer); // clear any pre-existing timer
    //     }
    //     const context = this; // get the current context
    //     timer = setTimeout(() => {
    //       fn.apply(context, args); // call the function if time expires
    //     }, wait);
    //   };
    // },
  },
};
