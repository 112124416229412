<template>
  <div v-if="loaded">
    <div class="step1" v-if="!next_step">
      <div class="logo mb-1">
        <img src="@/assets/img/logo.png" alt="logo" class="w-100" />
      </div>
      <div class="step1-content pb-4">
        <h3 class="title text-center mb-0 py-3 mt-4">
          Join your team on BuySimply
        </h3>
        <div class="w-100 d-flex justify-content-center">
          <p class="mb-0 py-2 f-14 mw-34 text-center">
            {{ user.creator_firstname }} {{ user.creator_lastname }}
            {{ user.creator_email }} has invited you to join
            <b>{{ user.company_name }} </b>on BuySimply
          </p>
        </div>
        <div class="card px-0 my-4">
          <div class="d-flex justify-content-center">
            <div class="mw-34 card-header">
              <p class="mb-0 py-2 f-14">
                Click <b>Join Team</b> to see all of our onboarding information
                and tips on how to use the most innovative buying and spend
                management platform that saves you time and money
              </p>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <div class="mw-34 w-100">
              <button @click="next()" class="btn btn-primary px-3 py-2 w-100">
                Join Team
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <step2 :user="user" v-else />
  </div>
</template>

<script>
import step2 from "./step2.vue";
export default {
  components: { step2 },
  data() {
    return {
      user: {},
      loaded: false,
      next_step: false,
    };
  },
  methods: {
    next() {
      this.next_step = true;
    },
    getUser() {
      // if (this.$route.params.token) {
      this.$store
        .dispatch("post", {
          endpoint: "users/created/one",
          details: {
            token: this.$route.params.token,
          },
        })
        .then((resp) => {
          if (resp.data) {
            console.log(resp.data);
            this.user = resp.data;
            this.loaded = true;
          } else {
            window.SweetAlert("error", "User not found, incorrect url token");
          }
        });
      // }
    },
  },
  created() {
    this.getUser();
  },
};
</script>

<style scoped lang="scss">
.step1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  .logo {
    width: 200px;
  }
  .step1-content {
    border-top: 4px solid #012538;
    width: 43em;
    margin: 20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    .invitee {
      font-size: 14px;
    }

    .card-header {
      border-bottom: 0;
    }
  }
  .mw-34 {
    max-width: 34em;
  }
}
</style>
