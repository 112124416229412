<template>
  <section class="profile manage d-grid">
    <div class="tp-setting">
      <div class="appr-req">
        <span>Settings</span>
        <h6 class="mb-0">Organization</h6>
      </div>
    </div>
    <div class="profile--content">
      <aside class="profile--content_right">
        <ul
          class="nav nav-pills justify-content-center align-items-center"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="organization-tab"
              data-bs-toggle="pill"
              data-bs-target="#organization"
              type="button"
              role="tab"
              aria-controls="organization"
              aria-selected="true"
            >
              <span>Organization Information</span>
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="kyc-tab"
              data-bs-toggle="pill"
              data-bs-target="#kyc"
              type="button"
              role="tab"
              aria-controls="kyc"
              aria-selected="false"
            >
              <span>KYC</span>
            </button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="preference-tab"
              data-bs-toggle="pill"
              data-bs-target="#preference"
              type="button"
              role="tab"
              aria-controls="preference"
              aria-selected="false"
            >
              <span>Preference</span>
            </button>
          </li> -->
        </ul>

        <div class="tab-content" id="pills-tabContent">
          <!-- Organization Details -->
          <div
            class="tab-pane fade show active"
            id="organization"
            role="tabpanel"
            aria-labelledby="organization-tab"
            v-if="checkPermissions('44', user_state.role_id)"
          >
            <div class="card">
              <div class="card-body">
                <form @submit.prevent="updateCompanyProfile()">
                  <div class="form-group">
                    <label for="">Organization Name <small>*</small> </label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="company.company_name"
                    />
                  </div>
                  <div class="input-flex">
                    <div class="form-group">
                      <label for="">Organization Address</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="company.delivery_address"
                      />
                    </div>
                    <div class="form-group">
                      <label for=""
                        >Contact Phone Number <small>*</small>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="company.phone"
                      />
                    </div>
                  </div>
                  <div class="input-flex">
                    <div class="form-group">
                      <label for="">Country <small>*</small></label>
                      <multiselect
                        v-model="sCompany"
                        :options="countries"
                        label="name"
                        @select="setState($event)"
                      >
                      </multiselect>
                    </div>
                    <div class="form-group">
                      <label for="">State <small>*</small></label>
                      <multiselect
                        v-model="company.state"
                        :options="states"
                        label="state"
                        @select="getCities($event)"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="form-btn">
                    <button
                      class="btn btn-primary f-13 px-3"
                      :disabled="loading"
                    >
                      <span v-if="!loading">Save Changes</span>
                      <span v-else>Loading...</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- kyc -->
          <div
            class="tab-pane fade"
            id="kyc"
            role="tabpanel"
            aria-labelledby="kyc-tab"
            v-if="checkPermissions('44', user_state.role_id)"
          >
            <div class="card">
              <div class="card-body">
                <div
                  class="kyc-success d-grid gap-24"
                  v-if="$store.state.user.bvn_verified == 1"
                >
                  <div class="kyc-success-header d-grid gap-18">
                    <div class="d-flex gap-12">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                      >
                        <path
                          d="M10 1.75C12.3206 1.75 14.5462 2.67187 16.1872 4.31282C17.8281 5.95376 18.75 8.17936 18.75 10.5C18.75 12.8206 17.8281 15.0462 16.1872 16.6872C14.5462 18.3281 12.3206 19.25 10 19.25C7.67936 19.25 5.45376 18.3281 3.81282 16.6872C2.17187 15.0462 1.25 12.8206 1.25 10.5C1.25 8.17936 2.17187 5.95376 3.81282 4.31282C5.45376 2.67187 7.67936 1.75 10 1.75ZM8.91 12.2262L6.96625 10.2812C6.89657 10.2116 6.81384 10.1563 6.7228 10.1186C6.63175 10.0809 6.53417 10.0615 6.43563 10.0615C6.33708 10.0615 6.2395 10.0809 6.14845 10.1186C6.05741 10.1563 5.97468 10.2116 5.905 10.2812C5.76427 10.422 5.68521 10.6129 5.68521 10.8119C5.68521 11.0109 5.76427 11.2018 5.905 11.3425L8.38 13.8175C8.44949 13.8875 8.53215 13.9431 8.62322 13.981C8.71429 14.019 8.81197 14.0385 8.91062 14.0385C9.00928 14.0385 9.10696 14.019 9.19803 13.981C9.2891 13.9431 9.37176 13.8875 9.44125 13.8175L14.5662 8.69125C14.6369 8.62186 14.693 8.53917 14.7315 8.44796C14.77 8.35674 14.7901 8.25881 14.7906 8.15981C14.791 8.0608 14.7719 7.96269 14.7342 7.87112C14.6966 7.77956 14.6412 7.69635 14.5712 7.6263C14.5012 7.55625 14.4181 7.50075 14.3266 7.463C14.2351 7.42524 14.137 7.40598 14.038 7.40632C13.939 7.40667 13.841 7.42661 13.7497 7.465C13.6585 7.50339 13.5757 7.55947 13.5063 7.63L8.91 12.2262Z"
                          fill="#198655"
                        />
                      </svg>
                      <span class="">KYC Verification Successful!</span>
                    </div>
                    <p class="mb-0">
                      You have completed your organization KYC process. No
                      further action is required
                    </p>
                  </div>
                  <!-- <div class="kyc-success-body">
                    <div class="kyc-title">
                      <h5 class="mb-0">KYC as a person</h5>
                    </div>
                    <div class="d-grid gap-40 pt-20">
                      <h5 class="mb-0">Contact Person Information</h5>
                      <form @submit.prevent="updateProfile()">
                        <div class="input-flex">
                          <div class="form-group">
                            <label for="">First Name <small>*</small> </label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="user.first_name"
                              disabled
                            />
                          </div>
                          <div class="form-group">
                            <label for="">Last Name <small>*</small></label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="user.last_name"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="input-flex">
                          <div class="form-group">
                            <label for="">Email Address <small>*</small></label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="user.email"
                              disabled
                            />
                          </div>
                          <div class="form-group">
                            <label for="">BVN <small>*</small></label>
                            <input
                              type="text"
                              class="form-control"
                              v-model="user.email"
                              disabled
                            />
                          </div>
                        </div>
                        <div class="input-flex">
                          <div class="form-group">
                            <label for=""
                              >Date of Birth <small>*</small>
                            </label>
                            <input type="date" class="form-control" disabled />
                          </div>
                          <div class="form-group form-gender">
                            <label for="" class="mb-0"
                              >Gender <small>*</small>
                            </label>
                            <div class="d-flex gap-12">
                              <div class="d-flex align-items-center gap-2">
                                <input type="radio" name="gender" />
                                <label for="" class="mb-0">Male </label>
                              </div>
                              <div class="d-flex align-items-center gap-2">
                                <input type="radio" name="gender" />
                                <label for="" class="mb-0">Female </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div> -->
                </div>

                <div v-else>
                  <div
                    class="kyc-p d-flex flex-column align-items-center pb-150"
                  >
                    <div class="kyc-p-header d-flex align-items-center">
                      <svg
                        width="20"
                        height="23"
                        viewBox="0 0 22 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11 0.5C4.925 0.5 0 5.425 0 11.5C0 17.575 4.925 22.5 11 22.5C17.075 22.5 22 17.575 22 11.5C22 5.425 17.075 0.5 11 0.5ZM10.5 5.5C10.2348 5.5 9.98043 5.60536 9.79289 5.79289C9.60536 5.98043 9.5 6.23478 9.5 6.5C9.5 6.76522 9.60536 7.01957 9.79289 7.20711C9.98043 7.39464 10.2348 7.5 10.5 7.5H11C11.2652 7.5 11.5196 7.39464 11.7071 7.20711C11.8946 7.01957 12 6.76522 12 6.5C12 6.23478 11.8946 5.98043 11.7071 5.79289C11.5196 5.60536 11.2652 5.5 11 5.5H10.5ZM9 9.5C8.73478 9.5 8.48043 9.60536 8.29289 9.79289C8.10536 9.98043 8 10.2348 8 10.5C8 10.7652 8.10536 11.0196 8.29289 11.2071C8.48043 11.3946 8.73478 11.5 9 11.5H10V14.5H9C8.73478 14.5 8.48043 14.6054 8.29289 14.7929C8.10536 14.9804 8 15.2348 8 15.5C8 15.7652 8.10536 16.0196 8.29289 16.2071C8.48043 16.3946 8.73478 16.5 9 16.5H13C13.2652 16.5 13.5196 16.3946 13.7071 16.2071C13.8946 16.0196 14 15.7652 14 15.5C14 15.2348 13.8946 14.9804 13.7071 14.7929C13.5196 14.6054 13.2652 14.5 13 14.5H12V10.5C12 10.2348 11.8946 9.98043 11.7071 9.79289C11.5196 9.60536 11.2652 9.5 11 9.5H9Z"
                          fill="#022438"
                        />
                      </svg>

                      <span>KYC Action required</span>
                    </div>
                    <div class="kyc-body">
                      <p>
                        Your organization KYC is pending, Kindly click the
                        button to complete the KYC process
                      </p>
                    </div>
                    <router-link
                      to="/onboarding?stage=3"
                      class="btn btn-primary f-13"
                      >Start KYC</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- preference -->
          <div
            class="tab-pane fade"
            id="preference"
            role="tabpanel"
            aria-labelledby="preference-tab"
            v-if="checkPermissions('44', user_state.role_id)"
          >
            <div class="card">
              <div class="card-body">
                <div class="pre-box">
                  <div class="d-grid gap-20">
                    <div class="d-grid gap-3">
                      <div class="pre-title-wrapper">
                        <h4 class="mb-0 pre-title">Email Notifications</h4>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 text-16">
                          Allow individuals to set email preferences
                        </p>
                        <div class="recurr-toggle">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <p class="mb-0 text-16 text-color-neutral">
                      Notify team members when;
                    </p>
                    <div class="pre-group d-grid">
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 text-18">Request is raised</p>
                        <input type="checkbox" name="" id="" />
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 text-18">Request has been endorsed</p>
                        <input type="checkbox" name="" id="" />
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 text-18">Request has been approved</p>
                        <input type="checkbox" name="" id="" />
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 text-18">
                          Action is done on a copied request
                        </p>
                        <input type="checkbox" name="" id="" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="d-grid gap-3">
                      <div
                        class="pre-title-wrapper d-flex gap-3 justify-content-between align-items-center"
                      >
                        <h4 class="mb-0 pre-title">
                          SMS Transaction Notifications
                        </h4>
                        <p class="mb-0 text-14">
                          <span class="fw-bold">NOTE:</span> Additional charges
                          will apply
                        </p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-12 text-16">
                          Enable SMS transaction notifications
                        </p>
                        <div class="recurr-toggle">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h5 class="mb-12 text-16 lh-30 text-color-neutral">
                        Send SMS notification on organization wide activity (e.g
                        Wallet, Online Payment, Purchases e.t.c)
                      </h5>
                      <table class="w-100">
                        <thead>
                          <tr>
                            <th>Recipient</th>
                            <th class="text-center">Wallet</th>
                            <th class="text-center">Online Payment</th>
                            <th class="text-center">Purchase</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Mr. Folu Dele-ijagbulu</td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                          </tr>
                          <tr>
                            <td>Kassy Devs</td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                          </tr>
                          <tr>
                            <td>Lekan ilelalaboye Devs</td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                            <td class="text-center">
                              <input type="checkbox" name="" id="" />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="pre-more d-grid">
                        <h4 class="mb-0 text-16">Add SMS recipient</h4>
                        <div class="d-flex pre-select-btn">
                          <select name="" id="" class="select-option">
                            <option value="1" selected>select sms type</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                          <button
                            class="btn btn-primary f-13 px-3 add-link-btn"
                            :disabled="loading"
                          >
                            <span v-if="!loading">Add Recipient</span>
                            <span v-else>Loading...</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="d-grid gap-3">
                      <div
                        class="pre-title-wrapper d-flex justify-content-between align-items-center"
                      >
                        <h4 class="mb-0 pre-title">Document Type</h4>
                      </div>
                    </div>
                    <div>
                      <h5 class="mb-12 mt-9 text-16 lh-30 text-color-neutral">
                        Update your document type to fit your organization's
                        need
                      </h5>
                      <div class="pre-role d-flex">
                        <span class="badge bg-gray">Contract</span>
                        <span class="badge bg-gray">General Office Memo</span>
                        <span class="badge bg-gray">Award Approval</span>
                        <span class="badge bg-gray">Leave Approval</span>
                      </div>
                      <div class="pre-more d-grid">
                        <h4 class="mb-0 text-16">Manage Document Type</h4>
                        <div class="pre-role d-flex flex-wrap">
                          <div class="pre-manage">
                            <span>Add Payee</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="21"
                                height="21"
                                rx="10.5"
                                fill="#DC3646"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="21"
                                height="21"
                                rx="10.5"
                                stroke="#DFDFDF"
                              />
                              <path
                                d="M15 7.5L8 14.5M8 7.5L15 14.5"
                                stroke="white"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        <div class="d-flex pre-select-btn">
                          <input
                            type="text"
                            name=""
                            id=""
                            class="form-control w-250"
                            placeholder="Enter document type"
                          />
                          <button
                            class="btn btn-primary f-13 px-3 add-link-btn"
                            :disabled="loading"
                          >
                            <span v-if="!loading">Add Document Type</span>
                            <span v-else>Loading...</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  </section>
</template>

<script>
  import { mapState } from "vuex";
  import countriesData from "@/data/country.json";
  import axios from "axios";
  import Multiselect from "vue-multiselect";
  export default {
    components: {
      Multiselect,
    },
    computed: {
      ...mapState({
        user_state: (context) => context.user,
      }),
    },
    data() {
      return {
        loading: false,
        user: {},
        loaded: false,
        states: [],
        cities: [],
        countries: [],
        password: {
          new_password: "",
          password: "",
          confirm_password: "",
        },
        nigeriaState: [],
        lga: [],
        company: {
          company_name: "",
          company_address: "",
          country: "Nigeria",
          registration_number: "",
          vat_number: "",
          tax_exempt: "",
          delivery_address: "",
          phone: "",
          state: {},
          city: {},
          lga: {},
        },
        kyc: {
          first_name: "",
          last_name: "",
          bvn: "",
        },
        sCompany: { name: "Nigeria", id: "161" },
        new_pswType: "password",
        confirm_pswType: "password",
        pswType: "password",

        // password modal
        showPasswordModal: false,
      };
    },
    methods: {
      setState(event) {
        this.company.country = event.name;
        if (event.name != "Nigeria") {
          this.states = event.states;
          this.states.map((item) => {
            item.state = item.name;
          });
        } else {
          this.states = this.nigeriaState;
        }
      },
      getUser() {
        this.$store.dispatch("getUser").then((resp) => {
          var data = {
            user: resp.data.user,
            profile: resp.data.profile,
            token: this.$store.state.token,
          };
          this.$store.commit("setUser", data);
        });
      },

      updateCompanyProfile() {
        // this.company.state = JSON.stringify(this.company.state);
        // this.company.city = JSON.stringify(this.company.city);
        if (!this.company.country || this.company.country.length < 1) {
          window.ToasterAlert("error", "Organization country is required");
          return false;
        }

        if (!this.company.city || !this.company.city.id) {
          window.ToasterAlert("error", "Organization city is required");
          return false;
        }

        if (!this.company.state || !this.company.state.id) {
          window.ToasterAlert("error", "Organization state is required");
          return false;
        }

        if (!this.company.city || this.company.city.length < 1) {
          window.ToasterAlert("error", "Organization city is required");
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "users/companyprofile/create/" + this.user.user_id,
            details: {
              ...this.company,
              state: JSON.stringify(this.company.state),
              city: JSON.stringify(this.company.city),
              lga: JSON.stringify(this.company.lga),
            },
          })
          .then(async (resp) => {
            await this.getUser();
            this.loading = false;
            if (resp.data) {
              window.ToasterAlert(
                "success",
                "Organization profile updated successfully"
              );
            }
          })
          .catch(() => {
            this.loading = false;
          });
      },
      verifyKYC() {
        // this.company.state = JSON.stringify(this.company.state);
        // this.company.city = JSON.stringify(this.company.city);
        if (!this.kyc.first_name || this.kyc.first_name.length < 1) {
          window.ToasterAlert(
            "error",
            "Organization contact first name is required"
          );
          return false;
        }

        if (!this.kyc.last_name || !this.kyc.last_name.length < 1) {
          window.ToasterAlert(
            "error",
            "Organization contact last name is required"
          );
          return false;
        }

        if (!this.kyc.bvn || !this.kyc.bvn.length < 1) {
          window.ToasterAlert("error", "Organization contact bvn is required");
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "company/kycverify/" + this.user.user_id,
            details: {
              ...this.kyc,
            },
          })
          .then(async (resp) => {
            await this.getUser();
            this.loading = false;
            if (resp.data) {
              window.ToasterAlert(
                "success",
                "Organization KYC updated successfully"
              );
            }
          })
          .catch(() => {
            this.loading = false;
            window.ToasterAlert(
              "error",
              "Organization KYC verification failed, please contact help"
            );
          });
      },
      updateProfile() {
        if (!this.user.phone || this.user.phone.length < 7) {
          window.ToasterAlert("error", "Phone field is required");
          return false;
        }
        this.loading = true;
        this.$store
          .dispatch("post", {
            endpoint: "users/userprofile/create/" + this.user.user_id,
            details: this.user,
          })
          .then(async (resp) => {
            await this.getUser();
            this.loading = false;
            if (resp.data) {
              window.ToasterAlert("success", "Profile updated successfully");
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$store.dispatch("handleError", err);
          });
      },
      getProfile() {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch(
            "get",
            "users/userprofile/read/" + this.$store.state.user.user_id
          )
          .then((resp) => {
            this.$store.commit("setLoader", false);
            this.loaded = true;
            this.user = resp.data;

            if (!resp.data) {
              window.SweetAlert("error", "User not found, Please login");
              setTimeout(() => {
                this.$store.commit("logout");
              }, 4000);
            }
          });
      },
      getCompanyProfile() {
        this.$store
          .dispatch(
            "get",
            "users/companyprofile/read/" + this.$store.state.user.user_id
          )
          .then((resp) => {
            this.company = resp.data[0];
            this.company.state = JSON.parse(this.company.state);
            this.company.city = JSON.parse(this.company.city);
            this.company.lga = JSON.parse(this.company.lga);
            if (!this.company.country) {
              this.company.country = this.sCompany.name;
            }
          });
      },
      async getStates() {
        await this.$store.dispatch("get", "jumia/getstates").then((resp) => {
          this.nigeriaState = resp.data;
          this.states = resp.data;
        });
      },
      async getKongaCities(state) {
        await this.$store
          .dispatch("get", "konga/getcities/" + state.state)
          .then((resp) => {
            this.lga = resp.data;
          });
      },
      getCities(event) {
        if (this.company.country != "Nigeria") {
          this.cities = event.cities;
          this.company.state = { id: event.id, state: event.name };
          return false;
        } else {
          if (event) {
            this.company.state = event;
          } else {
            this.company.state = { id: 25, state: "Lagos" };
          }

          this.getKongaCities(this.company.state);

          axios
            .get(
              `https://www.jumia.com.ng/region/${this.company.state.id}/cities/`,
              {
                headers: { Accept: "application/json" },
              }
            )
            .then((resp) => {
              // this.$store.commit("setLoader", false);
              this.cities = resp.data.cities;
              // var a = this.cities.find(
              //   (c) => c.name == this.product.delivery_location
              // );
              // this.city = a;
              this.$forceUpdate();
            });
        }
      },
      // password modal
      closeModal() {
        console.log("open");
        this.showPasswordModal = false;
      },
    },
    created() {
      console.log(this.user_state);
      this.countries = countriesData;
      this.getProfile();
      this.getCompanyProfile();
      this.getStates();
      this.getCities();
    },
  };
</script>

<style lang="scss" scoped>
  .badge {
    padding: 4px 10px !important;
    border-radius: 12px;
  }
  .bg-lightblue {
    background: #d7f0ff;
  }
  .bg-gray {
    background: #ececec;
  }
  .text-color-gray {
    color: #6a6a6a !important;
  }
  .text-color-neutral {
    color: #848484 !important;
  }

  .lh-30 {
    line-height: 30px !important;
  }
  .text-14 {
    color: #022438;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  .text-16 {
    color: #022438;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }
  .text-18 {
    color: #022438;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
  .mb-12 {
    margin-bottom: 12px;
  }
  .mt-9 {
    margin-top: 9px;
  }
  .form-control {
    border-radius: 8px !important;
  }
  .form-group label {
    color: #848484;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 7px;
  }
  .form-group input,
  .form-group input::placeholder {
    color: #848484;
    font-size: 14px;
    font-weight: 400;
    padding: 7px 10px;
  }
  table tbody tr td:first-child {
    text-align: left;
    width: auto;
  }
  table tbody tr td {
    border-bottom: none;
    font-weight: 400;
    color: #848484;
  }
  table thead tr th {
    padding: 17px 12px !important;
    color: #022438;
    font-weight: 500;
    font-size: 14px;
    background-color: none;
  }
  .kyc-success {
    .form-group input,
    .form-group input::placeholder {
      /* color: #848484; */
      font-size: 14px;
      /* font-weight: 400; */
      line-height: 16px;
      /* padding: 14px 10px; */
    }
  }
  .pt-20 {
    padding-top: 20px;
  }
  .pb-150 {
    padding-bottom: 150px;
  }
  .gap-12 {
    gap: 12px;
  }
  .gap-18 {
    gap: 18px;
  }
  .gap-20 {
    gap: 20px;
  }
  .gap-24 {
    gap: 24px;
  }
  .gap-40 {
    gap: 40px;
  }
  .profile {
    padding: 10px 3px;
    gap: 24px;
  }
  .profile--title {
    color: #022438;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    margin-bottom: 8px;
  }
  .profile--subtitle {
    color: #022438;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
  .profile--content_right {
    border-radius: 8px;
    border: 1px solid #dfdfdf;
    background: #fff;
  }
  .profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fafaff;
    border: 1px solid #022438;
    p {
      color: #022438;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .role {
    /* gap: 10px; */
    span {
      color: #505050;
      font-size: 8px;
      font-weight: 400;
      line-height: 9px;
    }
  }
  .role-wrapper {
    gap: 10px;
  }
  .p-name {
    color: #6a6a6a;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 4px;
  }
  .p-email {
    color: #848484;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
  .nav-link {
    color: #6a6a6a;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 10px 20px !important;
  }
  .nav-pills .nav-link.active {
    color: #fff !important;
    background: #022438 !important;
    border-radius: 0 !important;
  }
  .card {
    border: none;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0 0 8px 8px;
    padding: 10px;
  }
  .card-body {
    padding: 22px 0px;
  }
  .form-group {
    margin-bottom: 24px;
    width: 100%;
    small {
      color: #ff5555;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
    }
  }
  .form-btn {
    display: flex;
    justify-content: flex-end;
    padding-top: 8px;
  }
  .password-group {
    padding: 0px 10px 32px;
  }
  .password-group_title {
    color: #022438;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
  }

  .btn-outline-secondary {
    border-radius: 8px !important;
    border: 1px solid #022438 !important;
    background: #fff !important;
    /* padding: 16px; */
  }
  .btn-primary {
    /* padding: 16px; */
    border-radius: 8px !important;
  }

  /* password */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .password-modal-container {
    display: flex;
    width: 100%;
    height: 100%;
    background: #fdfdff;
  }
  .inner-modal {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-top: 22px;
  }
  .password-card {
    display: grid;
    padding: 20px 16px;
    gap: 8px;
    width: 100%;
    background: #fdfdff;
  }
  .password-card_tittle {
    color: #022438;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .password-close {
    position: absolute;
    right: 0px;
  }

  /* preference */
  .pre-title {
    color: #022438;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
  }
  .pre-title-wrapper {
    padding-bottom: 1rem;
    border-bottom: 1px solid #dfdfdf;
    flex-wrap: wrap;
  }
  .pre-text {
    color: #9f9f9f;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 9px;
  }
  .pre-group {
    gap: 12px;
  }
  .pre-box {
    display: grid;
    gap: 32px;
  }

  .pre-manage {
    display: flex;
    padding: 4px 10px;
    align-items: center;
    gap: 8px;
    border-radius: 26px;
    border: 1px solid #dfdfdf;
    background: #fff;
  }
  .pre-more {
    gap: 20px;
    margin-top: 20px;
  }
  .pre-select-btn {
    gap: 12px;
    flex-wrap: wrap;
  }
  .pre-role {
    gap: 10px;
    flex-wrap: wrap;
    span {
      color: #505050;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .add-link-btn {
    width: 100%;
    border: 1px solid #848484;
    padding: 10px;
  }
  .select-option {
    width: 100%;
    border: 1px solid #848484;
    border-radius: 8px;
    padding: 10px;
  }
  .notify-svg {
    flex-basis: 10%;
  }
  /* kyc */
  .kyc-p {
    gap: 18px;
    padding: 28px 0 50px;
    .kyc-p-header {
      span {
        margin-left: 10px;
        font-size: 18px;
        line-height: 21px;
        color: #022438;
      }
    }
    .kyc-body {
      max-width: 26em;
      text-align: center;
      font-size: 14px;
      line-height: 16px;
      color: #848484;
      /* margin-top: 15px; */
      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        font-weight: 100;
      }
    }
    .btn {
      margin-top: 0;
      padding: 7px 20px;
      border-radius: 8px;
    }
  }
  .kyc-success {
    .kyc-success-header {
      span {
        color: #198655;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
      }
      p {
        color: #6a6a6a;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .kyc-success-body {
      .kyc-title {
        padding-bottom: 24px;
        border-bottom: 1px solid #dfdfdf;
      }
      h5 {
        color: #afafaf;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
  .form-gender {
    display: grid;
    gap: 20px;
  }
  .recurr-toggle {
    .switch {
      position: relative;
      display: inline-block;
      width: 38px;
      height: 21px;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #fff;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border: 1px solid #cfcfcf;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 4px;
      bottom: 2.5px;
      background-color: #022438;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #022438;
      border: none;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #022438;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(16px);
      -ms-transform: translateX(16px);
      transform: translateX(16px);
      background-color: #fff;
      bottom: 3.5px;
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  @media (min-width: 780px) {
    .w-250 {
      width: 250px;
    }
    .ps-20 {
      padding-left: 20px;
    }

    .profile--content {
      width: 100%;
    }
    .profile--title {
      font-size: 16px;
      margin-bottom: 12px;
    }
    .profile--subtitle {
      font-size: 24px;
      line-height: 28px;
    }

    .profile--content_right {
      max-width: 1094px;
    }
    .profile-img {
      width: 81px;
      height: 81px;
      p {
        font-size: 35px;
        line-height: 40px;
      }
    }
    .p-name {
      text-align: center;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 8px;
    }
    .p-email {
      text-align: center;
      font-size: 16px;
      line-height: 18px;
    }
    .role-wrapper {
      margin-top: 24px;
    }
    .card {
      padding: 23px;
    }
    .card-body {
      padding: 0px;
    }
    .password-group {
      padding: 0px 28px 40px;
    }
    .nav {
      justify-content: flex-start !important;
      align-items: center;
      gap: 1rem !important;
      padding: 27px 0 0 24px;
    }
    .nav-pills .nav-link.active {
      color: #022438 !important;
      background: transparent !important;
      border-bottom: 3px solid #022438 !important;
    }
    .nav-link {
      border-bottom: 3px solid #fff !important;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      padding: 0 0 12px 0 !important;
    }
    .input-flex {
      display: flex;
      gap: 24px;
    }
    /* password */
    .overlay {
      align-items: center;
    }
    .password-modal-container {
      gap: 23px;
      width: auto;
      background: none;
    }
    .inner-modal {
      align-items: center;
      padding-top: 0px;
    }
    .password-card {
      display: grid;
      padding: 40px;
      gap: 16px;
      width: 580px;
      border-radius: 8px;
      background: #fdfdff;
    }
    .password-close {
      position: relative;
      top: -26%;
      left: 11px;
    }
    .password-card_tittle {
      font-size: 22px;
      line-height: 25px;
    }
    .role span {
      font-size: 12px;
      line-height: 14px;
    }
    .badge {
      padding: 6px 10px !important;
    }
    /* preference */
    .pre-box {
      display: grid;
      gap: 40px;
    }
    .pre-title {
      font-size: 20px;
      line-height: 23px;
    }
    /* .pre-role span {
    font-size: 12px;
    line-height: 14px;
  } */
    .pre-select-btn {
      flex-wrap: nowrap;
    }
    .add-link-btn {
      width: 250px;
    }
    .select-option {
      width: 250px;
    }
    .notify-svg {
      flex-basis: 1%;
    }
    /* kyc */
    .kyc-p {
      .kyc-p-header {
        span {
          font-size: 22px;
          line-height: 25px;
        }
      }
      .kyc-body {
        p {
          font-size: 16px;
          line-height: 28px;
        }
      }
      .btn {
        font-size: 16px;
        line-height: 18px;
      }
    }
    .kyc-success {
      .kyc-success-header {
        svg {
          width: 24px;
          height: 24px;
        }
        span {
          font-size: 22px;
          line-height: 25px;
        }
        p {
          font-size: 16px;
          line-height: 18px;
        }
      }
      .kyc-success-body {
        .kyc-title {
          h5 {
            font-size: 18px;
            line-height: 21px;
          }
        }
      }
    }
    /* preference */
    .pre-title-wrapper {
      flex-wrap: nowrap;
    }
  }
</style>
