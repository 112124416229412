<template>
  <div class="jumia">
    <jumia-navbar></jumia-navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import JumiaNavbar from "@/components/dashboard/jumia/navbar";
export default {
  components: { JumiaNavbar },
};
</script>

<style></style>
