<template>
  <div class="login-wrapper">
    <div class="">
      <div class="login-section-wrapper">
        <router-link to="/" class="logo mb-4">
          <img src="@/assets/img/logo.png" alt="logo" class="w-100" />
        </router-link>
        <div class="login-section">
          <div class="logo"></div>
          <div class="login-header">
            <!-- <h3 class="text-center">Welcome Back!</h3>
            <p class="lh-descp">
              Ready to experience the new simplified buying experience.
            </p> -->
          </div>
          <div class="login-body mt-5">
            <form @submit.prevent="login()">
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  v-model="business.email"
                  placeholder="Email"
                  required
                />
              </div>
              <div class="form-group mb-2">
                <div class="input-group">
                  <input
                    :type="pswType"
                    class="form-control input-psw"
                    placeholder="Password"
                    v-model="business.password"
                    required
                  />
                  <span
                    class="input-group-text"
                    id="togglePsw"
                    @click.prevent="togglePsw()"
                  >
                    <i class="fa fa-eye" v-if="pswType == 'password'"></i>
                    <i class="fa fa-eye-slash" v-else></i>
                  </span>
                </div>
              </div>
              <div class="forgot-psw d-flex justify-content-between">
                <div class="signup">
                  <span>
                    Don't have an account yet?
                    <router-link to="/register" class="">Sign Up</router-link>
                  </span>
                </div>
                <router-link to="/forget-password" class="rec-psw text-primary"
                  >Recover Password</router-link
                >
              </div>
              <div class="form-group mt-5">
                <button
                  type="submit"
                  :disabled="loading"
                  class="btn btn-primary w-100 submit"
                >
                  <span v-if="!loading">Login</span>
                  <span v-else>Loading...</span>
                </button>
                <p class="text-center f-14 mt-3">
                  New here?
                  <a :href="$store.state.demoURL" class="">Request demo</a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script
  src="https://script.tapfiliate.com/tapfiliate.js"
  type="text/javascript"
  async
></script>
<script type="text/javascript">
(function (t, a, p) {
  t.TapfiliateObject = a;
  t[a] =
    t[a] ||
    function () {
      (t[a].q = t[a].q || []).push(arguments);
    };
})(window, "tap");

tap("create", "45795-1e389b", { integration: "javascript" });
tap("detect");
</script>
<script>
export default {
  data() {
    return {
      business: {
        email: "",
        password: "",
      },
      loading: false,
      pswType: "password",
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("post", { endpoint: "users/login", details: this.business })
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          this.business.email = "";
          this.business.password = "";
          // resp.data.isSupplier = resp.data.is_supplier;
          // resp.data.isBuyer = resp.data.is_buyer;
          this.$store.commit("setUser", resp.data);
          this.$store.commit("setIsBuyerDefault", true);
          window.location = "/dashboard";
          // if (resp.data.is_buyer) {
          //   this.$store.commit("setIsBuyerDefault", true);
          //   window.location = "/dashboard";
          // } else {
          //   this.$store.commit("setIsBuyerDefault", false);
          //   window.location = "/supplier/dashboard";
          // }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    togglePsw() {
      this.pswType == "password"
        ? (this.pswType = "text")
        : (this.pswType = "password");
    },
  },
  created() {
    window.fbq("trackCustom", "LoginPage");
  },
};
</script>

<style scoped lang="scss">
.login-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  .logo {
    width: 200px;
  }
  .login-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    padding: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .login-header {
      .lh-descp {
        max-width: 25em;
        text-align: center;
        color: grey;
        font-size: 14px;
      }
    }
    .login-body {
      min-width: 28em;
      .form-group {
        margin-bottom: 20px;
        .form-control {
          height: 48px;
          font-size: 14px;
        }
        .google-signin {
          border: 1px solid #8080803d;
          background-color: transparent;
          font-size: 15px;
          padding: 15px 0;
        }
      }
      .forgot-psw {
        .form-check-label {
          font-size: 13px;
        }
        a {
          font-size: 13px;
        }
      }
      .submit {
        padding: 13px;
        background-color: #0a0a0a;
        border: none;
        &:hover {
          background-color: #676464;
        }
      }
      .input-group-text {
        border-left: none;
        cursor: pointer;
      }
    }
    .signup {
      font-size: 14px;
      color: #808080ab;
      a {
        font-weight: bold;
        color: #0a0a0a;
      }
    }
  }
}
@media (max-width: 576px) {
  .img-row {
    display: none;
  }
  .login-section .login-body {
    min-width: 22em !important;
  }
  .login-section-wrapper {
    padding-right: 0;
    min-height: 97vh;
  }
  .login-section-wrapper .login-section {
    padding: 26px;
  }
}
</style>
