<template>
  <!-- <div class="step2">
    <div class="step2-header">
      <div class="bsmp-logo">
        <img src="@/assets/img/logo.png" class="w-100" />
      </div>
      <h1 class="mt-5 mb-3 text-center">
        Join <span class="uppercase">{{ user.company_name }}</span> on BuySimply
      </h1>
      <p>BuySimply is a procurement service system provider for your company</p>
    </div>
    <div class="step2-body mt-5">
      <div class="register mt-4">
        <div class="form">
          <form @submit.prevent="submit()">
            <div class="form-group">
              <label for="">Email</label>
              <input
                type="email"
                class="form-control"
                :value="user.email"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="">Password</label>
              <input
                type="password"
                class="form-control"
                v-model="user.password"
              />
            </div>
            <button
              class="btn btn-primary w-100 py-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">Create Account</span>
              <span v-else>Loading</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </div> -->

  <div class="step2">
    <div class="">
      <div class="login-section-wrapper">
        <div class="logo mb-4">
          <img src="@/assets/img/logo.png" alt="logo" class="w-100" />
        </div>
        <div class="login-section">
          <div class="logo"></div>
          <div class="login-header">
            <h3 class="text-center">Complete Registration</h3>
          </div>
          <div class="login-body mt-4">
            <form @submit.prevent="submit()">
              <div class="form-group mb-2">
                <label class="f-14">Email</label>
                <input
                  type="email"
                  class="form-control"
                  :value="user.email"
                  disabled
                />
              </div>
              <div class="form-group mb-2">
                <label class="f-14">Password</label>
                <div class="input-group">
                  <input
                    :type="pswType"
                    class="form-control input-psw"
                    placeholder="Password"
                    v-model="user.password"
                    required
                    @input="checkPassword"
                  />
                  <span
                    class="input-group-text"
                    id="togglePsw"
                    @click.prevent="togglePsw()"
                  >
                    <i class="fa fa-eye" v-if="pswType == 'password'"></i>
                    <i class="fa fa-eye-slash" v-else></i>
                  </span>
                </div>
                <div class="psw-checker mt-2">
                  <ul>
                    <li
                      v-bind:class="{
                        is_valid: contains_eight_characters,
                      }"
                    >
                      <svg
                        v-if="!contains_eight_characters"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 241.171 241.171"
                        style="
                          enable-background: new 0 0 241.171 241.171;
                          width: 10px;
                        "
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            id="Close"
                            d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                            fill="#000000"
                            style="fill: rgb(220, 54, 70)"
                          ></path>
                        </g>
                      </svg>
                      <svg
                        v-else
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                          fill="rgb(0,0,0)"
                          fill-rule="evenodd"
                          style="fill: rgb(25, 134, 85)"
                        ></path>
                      </svg>

                      <span> at least 8 characters</span>
                    </li>
                    <li v-bind:class="{ is_valid: contains_number }">
                      <svg
                        v-if="!contains_number"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 241.171 241.171"
                        style="
                          enable-background: new 0 0 241.171 241.171;
                          width: 10px;
                        "
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            id="Close"
                            d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                            fill="#000000"
                            style="fill: rgb(220, 54, 70)"
                          ></path>
                        </g>
                      </svg>

                      <svg
                        v-else
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                          fill="rgb(0,0,0)"
                          fill-rule="evenodd"
                          style="fill: rgb(25, 134, 85)"
                        ></path>
                      </svg>

                      <span> one number</span>
                    </li>
                    <li v-bind:class="{ is_valid: contains_uppercase }">
                      <svg
                        v-if="!contains_uppercase"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 241.171 241.171"
                        style="
                          enable-background: new 0 0 241.171 241.171;
                          width: 10px;
                        "
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            id="Close"
                            d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                            fill="#000000"
                            style="fill: rgb(220, 54, 70)"
                          ></path>
                        </g>
                      </svg>

                      <svg
                        v-else
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                          fill="rgb(0,0,0)"
                          fill-rule="evenodd"
                          style="fill: rgb(25, 134, 85)"
                        ></path>
                      </svg>

                      <span> one uppercase letter</span>
                    </li>
                    <li
                      v-bind:class="{
                        is_valid: contains_special_character,
                      }"
                    >
                      <svg
                        v-if="!contains_special_character"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 241.171 241.171"
                        style="
                          enable-background: new 0 0 241.171 241.171;
                          width: 10px;
                        "
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            id="Close"
                            d="M138.138,120.754l99.118-98.576c4.752-4.704,4.752-12.319,0-17.011c-4.74-4.704-12.439-4.704-17.179,0 l-99.033,98.492L21.095,3.699c-4.74-4.752-12.439-4.752-17.179,0c-4.74,4.764-4.74,12.475,0,17.227l99.876,99.888L3.555,220.497 c-4.74,4.704-4.74,12.319,0,17.011c4.74,4.704,12.439,4.704,17.179,0l100.152-99.599l99.551,99.563 c4.74,4.752,12.439,4.752,17.179,0c4.74-4.764,4.74-12.475,0-17.227L138.138,120.754z"
                            fill="#000000"
                            style="fill: rgb(220, 54, 70)"
                          ></path>
                        </g>
                      </svg>

                      <svg
                        v-else
                        fill="none"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          clip-rule="evenodd"
                          d="m20.7096 5.79536c.3891.39188.3869 1.02505-.005 1.41421l-11.07688 11.00003c-.38996.3872-1.01933.3872-1.40928 0l-4.92308-4.8889c-.39188-.3892-.39409-1.0223-.00493-1.4142s1.02233-.3941 1.41421-.005l4.21844 4.1892 10.37232-10.30027c.3918-.38916 1.025-.38695 1.4142.00493z"
                          fill="rgb(0,0,0)"
                          fill-rule="evenodd"
                          style="fill: rgb(25, 134, 85)"
                        ></path>
                      </svg>

                      <span> one symbol (e.g. !@#$%^&*~_+-)</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group mb-2">
                <label class="f-14">Confirm Password</label>
                <div class="input-group">
                  <input
                    :type="pswType"
                    class="form-control input-psw"
                    placeholder="Confirm Password"
                    v-model="user.confirm_password"
                    required
                  />
                  <span
                    class="input-group-text"
                    id="togglePsw"
                    @click.prevent="togglePsw()"
                  >
                    <i class="fa fa-eye" v-if="pswType == 'password'"></i>
                    <i class="fa fa-eye-slash" v-else></i>
                  </span>
                </div>
              </div>
              <div class="forgot-psw d-flex justify-content-end">
                <div class="signup">
                  <span> Please create a strong password </span>
                </div>
              </div>

              <div class="form-group mt-5">
                <button
                  type="submit"
                  :disabled="loading"
                  class="btn btn-primary w-100 submit"
                >
                  <span v-if="!loading">Join Organization</span>
                  <span v-else>Loading...</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {},
  },
  data() {
    return {
      register: false,
      loading: false,
      pswType: "password",
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
    };
  },
  methods: {
    checkPassword() {
      /* eslint-disable no-useless-escape */
      this.password_length = this.user.password.length;
      const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      if (this.password_length > 7) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.user.password);
      this.contains_uppercase = /[A-Z]/.test(this.user.password);
      this.contains_special_character = format.test(this.user.password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    togglePsw() {
      this.pswType == "password"
        ? (this.pswType = "text")
        : (this.pswType = "password");
    },
    submit() {
      // console.log(this.user);
      if (this.user.password.length < 1) {
        window.SweetAlert("error", "Password field is required");
        return false;
      }
      if (this.user.confirm_password != this.user.password) {
        window.ToasterAlert(
          "error",
          "Password and Confirm Password does not match"
        );
        return false;
      }
      if (!this.valid_password) {
        window.ToasterAlert("error", "Password not strong enough");
        return false;
      }
      this.loading = true;
      this.$store
        .dispatch("post", {
          endpoint: "users/signup",
          details: {
            company_slug: this.user.company_slug,
            email: this.user.email,
            role_id: this.user.role_id,
            password: this.user.password,
          },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          this.$store.commit("setUser", resp.data);
          this.$store.commit("setIsBuyerDefault", true);
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("handleError", error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.step2 {
  .login-section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    .logo {
      width: 200px;
    }
    .login-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: fit-content;
      width: fit-content;
      padding: 30px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      .login-header {
        .lh-descp {
          max-width: 25em;
          text-align: center;
          color: grey;
          font-size: 14px;
        }
      }
      .login-body {
        min-width: 28em;
        .form-group {
          margin-bottom: 20px;
          .form-control {
            height: 48px;
            font-size: 14px;
          }
          .google-signin {
            border: 1px solid #8080803d;
            background-color: transparent;
            font-size: 15px;
            padding: 15px 0;
          }
        }
        .forgot-psw {
          .form-check-label {
            font-size: 13px;
          }
          a {
            font-size: 13px;
          }
        }
        .submit {
          padding: 13px;
          background-color: #0a0a0a;
          border: none;
          &:hover {
            background-color: #676464;
          }
        }
        .input-group-text {
          border-left: none;
          cursor: pointer;
        }
      }
      .signup {
        font-size: 13px;
        font-weight: bold;
        color: #0a0a0a;
        line-height: 1;
      }
    }
  }

  @media (max-width: 576px) {
    .img-row {
      display: none;
    }
    .login-section .login-body {
      min-width: 22em !important;
    }
    .login-section-wrapper {
      padding-right: 0;
      min-height: 97vh;
    }
    .login-section-wrapper .login-section {
      padding: 26px;
    }
  }
}
</style>
