<template>
  <div class="login-wrapper">
    <div class="row" v-if="loaded">
      <div class="login-section-wrapper">
        <div class="logo mb-4">
          <img src="@/assets/img/logo.png" alt="logo" class="w-100" />
        </div>
        <div class="login-section">
          <div class="logo"></div>
          <div class="login-header">
            <h4 class="text-center">Reset Password</h4>
            <p class="lh-descp">Enter new password</p>
          </div>
          <div class="login-body mt-3">
            <form @submit.prevent="resetPassword()">
              <div class="form-group">
                <label class="f-13" for="">New Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="password"
                  placeholder="New Password"
                  required
                />
              </div>
              <div class="form-group">
                <label class="f-13" for="">Confirm New Password</label>
                <input
                  type="password"
                  class="form-control"
                  v-model="confirm_password"
                  placeholder="Confirm New Password"
                  required
                />
              </div>

              <div class="form-group mt-3">
                <button
                  type="submit"
                  :disabled="loading"
                  class="btn btn-primary w-100 submit"
                >
                  <span v-if="!loading">Reset Password</span>
                  <span v-else>Loading...</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {},
      loading: false,
      password: "",
      confirm_password: "",
      loaded: false,
    };
  },
  methods: {
    resetPassword() {
      if (this.password.length < 5) {
        window.ToasterAlert(
          "error",
          "Password should contain a minimum of 5 characters"
        );
        return false;
      }
      if (this.confirm_password.length < 1) {
        window.ToasterAlert("error", "Confirm Password field is required");
        return false;
      }
      if (this.password !== this.confirm_password) {
        window.ToasterAlert(
          "error",
          "New password and confirm new password field should be the same"
        );
        return false;
      }
      this.loading = true;
      this.$store
        .dispatch("post", {
          endpoint: "users/resetpassword/final",
          details: { new_password: this.password, user_id: this.user.id },
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          window.ToasterAlert("success", "Password changed successfully");
          this.$router.push({ name: "login" });
        })
        .catch((error) => {
          this.loading = false;
          this.$store.dispatch("handleError", error);
        });
    },

    verifyToken() {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "users/resetpassword/verify",
          details: { token: this.$route.query.token },
        })
        .then((resp) => {
          this.$store.commit("setLoader", true);
          this.user = resp.data.user;
          this.loaded = true;
        })
        .catch((error) => {
          this.$router.push({ name: "login" });
          this.loading = false;
          this.$store.dispatch("handleError", error);
        });
    },
  },
  created() {
    this.verifyToken();
  },
};
</script>

<style scoped lang="scss">
.img-row {
  background-color: #2d68cb;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-wrapper {
    width: 78%;
  }
}
.login-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  .logo {
    width: 200px;
  }
  .login-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    width: fit-content;
    padding: 28px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    .login-header {
      .lh-descp {
        max-width: 25em;
        text-align: center;
        color: grey;
        font-size: 14px;
      }
    }
    .login-body {
      min-width: 28em;
      .form-group {
        margin-bottom: 20px;
        .form-control {
          height: 48px;
          font-size: 14px;
        }
        .google-signin {
          border: 1px solid #8080803d;
          background-color: transparent;
          font-size: 15px;
          padding: 15px 0;
        }
      }
      .forgot-psw {
        .form-check-label {
          font-size: 13px;
        }
        a {
          font-size: 13px;
        }
      }
      .submit {
        padding: 13px;
        background-color: #0a0a0a;
        border: none;
        &:hover {
          background-color: #676464;
        }
      }
    }
    .signup {
      font-size: 14px;
      color: #808080ab;
      a {
        font-weight: bold;
        color: #0a0a0a;
      }
    }
  }
}
@media (max-width: 576px) {
  .img-row {
    display: none;
  }
  .login-section .login-body {
    min-width: 22em;
  }
  .login-section-wrapper {
    padding-right: 0;
    min-height: 97vh;
  }
}
</style>
