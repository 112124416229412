import Xerox from "../views/dashboard/xerox/index.vue";
import XeroxProductView from "../views/dashboard/xerox/view.vue";
import XeroxCart from "../views/dashboard/xerox/cart.vue";
import XeroxCheckout from "../views/dashboard/xerox/checkout.vue";

const XeroxRoutes = [
  {
    path: "/xerox",
    name: "xerox",
    component: Xerox,
  },
  {
    path: "/xerox/product/:link",
    name: "xerox-product-view",
    component: XeroxProductView,
  },
  {
    path: "/xerox/cart",
    name: "xerox-cart",
    component: XeroxCart,
  },
  {
    path: "/xerox/checkout",
    name: "xerox-checkout",
    component: XeroxCheckout,
  },
];
export default XeroxRoutes;
